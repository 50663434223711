import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from 'tss-react/mui';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState, useRef } from 'react';
import plusIcon from "../../assets/plus_icon.svg";
import { AuthContext } from '../../context/context';
import { addNewBoard, createAndAddBoardName, changeIntegrationBoardName } from '../../context/intuit-service';
import OverlaySubscription from '../Overlay';
import { countMatchingOwners } from './helpers';
import { ButtonGroup, Icon } from "semantic-ui-react";
import addCollaborator from "../../assets/undraw_collaborators_re_hont.svg";
import { Box } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

const PREFIX = 'NewBoard';

const classes = {
  paper: `${PREFIX}-paper`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.paper}`]: { minWidth: '400px' }
}));

export default function NewBoard(props) {

  const {
    state: {
      user, switchUser, isAuthenticated, access_token, purchasing, projects, allBoards, clientsPurchased
    },
    dispatch,
  } = React.useContext(AuthContext);

  const [name, setName] = useState(props.board ? props.board.boardName : 'Project Name');
  const [backDropOpen, setBackDropOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleCloseBackDrop = () => {
    setBackDropOpen(false);
  };

  const handleToggleBackDrop = () => {
     
    setBackDropOpen(!backDropOpen);
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      buttonRef.current.click();
    }
  }

  useEffect(() => {
     
    console.log(clientsPurchased - countMatchingOwners(allBoards));
  }, [clientsPurchased, allBoards])

  const changeBoardName = async () => {
    let data = {
      newName: name,
      board_id: props.board._id
    }
    try {
      let newBoards = {...allBoards};
      for (let i = 0; i < newBoards.boards.length; i = i + 1) {
        if (newBoards.boards[i]._id === props.board._id) {
          newBoards.boards[i].boardName = name;
          break;
        }
      }
       
      const message = await changeIntegrationBoardName(data);
      if (message.status === 200) {
         
         
        dispatch({
          type: "SET_ALLBOARDS",
          payload: message.data.userBoards,
        });
      }
    } catch(error) {
      console.error(error);
    }
    handleCloseBackDrop();
  }

  // creating a new client
  const createNewBoard = async () => {
    if (props.edit) {
      changeBoardName();
      return;
    }
    let data = { sharedId: switchUser.userName, boardName: name, boardId: props.boardId, duplicateClient: false }
    if (props.board) {
      data.boardIdReplace = props.board._id

      let newBoards = allBoards;
      for (let i = 0; i < newBoards.boards.length; i = i + 1) {
        if (newBoards.boards[i]._id === props.board._id) {
          newBoards.boards[i].boardName = name;
          break;
        }
      }

      const addBoard = await createAndAddBoardName(data);
      if (addBoard.status === 200) {
        dispatch({
          type: 'SET_ALLBOARDS',
          payload: newBoards
        });
         
      }

    } else {

      const addBoardResponse = await createAndAddBoardName(data);
      if (addBoardResponse.status === 200) {
        props.getBoards(true);
      }
    }
    handleCloseBackDrop();
  };
    const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    textTransform: 'none',
    backgroundColor: 'black', // Change to desired hover background color
    textAlign: 'left',
    color: 'white', // Change to desired hover text color
    width: '100%',
    borderRadius: isHovered ? '5px' : '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-center',
    fontWeight: 700,
    transition: 'border-radius 0.3s ease',
    height: '40px',
    border: 'solid 1px',
  };

   const handleMenuItemClick = () => {
     if (props.handleClose) {
       props.handleClose(); // Close the parent menu
     }
     handleToggleBackDrop(); // Open the dialog
   };

  const newBoard = (
    (<Root>
      {props.edit ?
        <MenuItem onClick={handleMenuItemClick}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="Rename" />
        </MenuItem>

        :
        <>
          {props.noClients ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                width: '100%',
                height: '400px',
                // border: '2px solid #ccc',
                borderRadius: '10px',
                marginBottom: '0px',
              }}
            >
              <Typography>
                <h1>👋 Welcome to Datatrixs! Let's Get Started</h1>
              </Typography>
              {/* <img src={addCollaborator} style={{ width: '40%' }} /> */}
              <Typography sx={{ width: "50%", mt: 4, mb: 4 }}>
                <p>
                Your data is connected—now it’s time to set up your first project.
                </p>
                <p>
                As a <strong>business owner</strong> or <strong>finance professional</strong>, you’ll use Datatrixs to uncover financial insights, monitor key metrics, and generate reports that support smarter, data-driven decisions.               
                </p>
              </Typography>

              {/* <Button 
                variant="contained"
                className="first-step"
                sx={{
                  bgcolor: 'black',
                  textTransform: 'none',
                  color: 'white',
                  borderRadius: '40px',
                  fontWeight: 700,
                  height: '40px',
                  borderRadius: isHovered ? '5px' : '40px',
                  transition: 'border-radius 0.3s ease',
                }}
                onClick={handleToggleBackDrop}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                >
                <AddIcon />
                <span style={{ marginLeft: '8px', marginRight: '8px' }}>
                  Add a Client
                </span>
              </Button> */}
            </Box>
          ) : (
            <Typography
              style={{ paddingBottom: "0px" }}
              gutterBottom
              sx={{
                color: "text.primary",
                fontSize: 15
              }}>

              {purchasing && purchasing.planDetails && purchasing.planDetails.clients &&
                purchasing.planDetails.clients[0] && purchasing.planDetails.clients[0].quantity && 
                countMatchingOwners(allBoards) < purchasing.planDetails.clients[0].quantity


                ?
              <Button
                variant='text'
                className='ui button icon first-step'
                style={buttonStyle}
                onClick={handleToggleBackDrop}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                >
                <i className="plus cirlce icon" fontSize="medium"></i>
                
                <span style={{ marginLeft: '8px' }}>Add a Project</span>
              </Button>
            
                :
                <>
                  {clientsPurchased - countMatchingOwners(allBoards) > 0

                    ?
                    <p
                      style={{
                        paddingBottom: '0px',
                      }}
                      onClick={handleToggleBackDrop}>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '0px',
                        }}
                      >
                        <Button
                          variant='text'
                          className='ui button icon first-step'
                          style={buttonStyle}
                          onClick={handleToggleBackDrop}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          >
                          {/* <i className="plus cirlce icon" fontSize="medium"></i> */}
                          <AddIcon />
                          <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>Add a Project</span>
                        </Button>
                      </div>

                    </p>
                    :
                    <OverlaySubscription />


                  }


                </>
              }
            </Typography>
          )}
        </>
      }
    </Root>)
  );
  return (
    <>
      {newBoard}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
        open={backDropOpen}
        onClose={handleCloseBackDrop}
      >
        <Dialog
          classes={{ paper: classes.paper }}
          onClose={handleCloseBackDrop}
          open={backDropOpen}
        >
          <Card sx={{ minWidth: 275, p: 4 }}>
            <CardContent>
              <Typography variant="h5" sx={{ pb: 2, width: "100%" }}>
                Project Name
              </Typography>
              <TextField
                autoFocus
                sx={{ pb: 2, width: "100%" }}
                onChange={(e) => setName(e.target.value)}
                required
                value={name}
                variant="outlined"
                onKeyDown={handleEnterPress}
              />
              <Button
                sx={{ width: "100%", backgroundColor: "black", color: "white" }}
                onClick={createNewBoard}
                variant="contained"
                ref={buttonRef}
              >
                {props.edit ? "Save" : "Create"}
              </Button>
            </CardContent>
          </Card>
        </Dialog>
      </Backdrop>
    </>
  );
}
