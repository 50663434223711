import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ShareIcon from '@mui/icons-material/Share';
import TextField from '@mui/material/TextField';
import React, { useState, useContext, useEffect } from 'react';
import axios from '../../context/axios';
import Alerts from '../Misc/Alerts';
import { AuthContext } from '../../context/context';

import { getTeams } from '../../context/team-service';
import InviteView from './InviteView';
import Profile from '../../assets/profile.png';
import { Icon } from 'semantic-ui-react';
import { Box, Button, InputBase, MenuItem, Select, ListSubheader, Divider } from '@mui/material';
import { Card } from 'react-bootstrap';

// Styled components
const Root = styled('div')(({ theme }) => ({
  margin: 'auto',
}));

const ShareDataContainer = styled('div')(({ theme }) => ({
  padding: '0 2rem',
  display: 'flex',
  justifyContent: 'space-between',
  '& input': {
    margin: '0 3rem 0 1rem',
  },
}));

const NestDiv = styled('div')({
  border: '2px solid #5abcdc',
  borderRadius: '10px',
  padding: '.5rem .5rem',
  '& input': {
    margin: '0 5rem 0 1rem',
  },
});

const InviteButton = styled(Button)({
  width: '15%',
  backgroundColor: '#220FFE',
  fontWeight: 'bold',
  fontFamily: 'cerebri sans',
  padding: '.5rem 3.5rem',
  borderRadius: '1rem',
  fontSize: '1rem',
  margin: '0 1rem',
  color: '#fff', // Make sure the text is visible
});

const DeleteButton = styled(Button)({
  color: '#aaa',
  margin: '0 0 0 0',
  '& svg': {
    fontSize: '2rem',
  },
});

const FormDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const DataDiv = styled('div')({
  margin: '2.5rem 1rem',
});

const ButtonStyle2 = styled(Button)({
  backgroundColor: 'transparent',
  border: 'none',
  whiteSpace: 'normal',
  overflow: 'visible',
  textAlign: 'left',
  color: '#220FFE',
});

// Rewritten DialogTitle for Mui v5
const CustomDialogTitle = ({ children, onClose, ...other }) => (
  <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
    <Typography variant="h6">{children}</Typography>
  </DialogTitle>
);

const ShareDiv = (props) => {
  return (
    <Root>
      <div style={{ margin: '1rem 0' }}>
        <ShareDataContainer>
          <InputBase fullWidth value={props.data.email} />

          <Select
            value={props.data.access}
            name={props.data.email}
            onChange={props.modifyUser}
            disableUnderline
          >
            <MenuItem value="CAN_MODIFY">Can Edit</MenuItem>
            <ListSubheader>Can edit but not share with others.</ListSubheader>
            <MenuItem value="CAN_VIEW">Can View</MenuItem>
            <ListSubheader>Cannot edit or share with others.</ListSubheader>
          </Select>
          <div>
            <DeleteButton onClick={() => props.deleteUser(props.data._id)}>
              <DeleteForeverOutlinedIcon />
            </DeleteButton>
          </div>
        </ShareDataContainer>
        <Divider />
      </div>
    </Root>
  );
};

const InviteBoard = (props) => {
  const {
    state: {
      user, allBoards, selectedBoard,
    },
  } = React.useContext(AuthContext);

  const { state } = React.useContext(AuthContext);
  const { companyName } = state;
  const [open, setOpen] = React.useState(false);

  const [successMsg, setSuccessMsg] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [openSuccessMsg, setOpenSuccessMsg] = React.useState(false);
  const [openErrorMsg, setOpenErrorMsg] = React.useState(false);
  const [openMsg, setOpenMsg] = React.useState(false);
  const [prog, setProg] = React.useState(true);

  const [totalAvailable, setTotalAvailable] = useState(0);

  const [reload, setReload] = useState(true);
  const [emails, setEmails] = useState([]);
  const [access, setAccess] = useState('CAN_VIEW');
  const [enteredData, setEnteredData] = useState('');
  const [teamMembers, setTeamMembers] = React.useState([]);
  const [inviteRefresh, setInviteRefresh] = React.useState(false);

  const fetchTeam = async () => {
    try {
      const teamMembersResponse = await getTeams();
      if (teamMembersResponse?.data?.data?.[0]) {
        const { teamMembers } = teamMembersResponse.data.data[0];
        setTeamMembers(teamMembers || []);
      } else {
        setTeamMembers([]);
      }
    } catch (error) {
      console.error('Failed to fetch teams:', error);
    }
  };

  React.useEffect(() => {
    const fetchTeamData = async () => {
      try {
        await fetchTeam();
      } catch (error) {
        console.error(error);
      }
    };
    fetchTeamData();
  }, []);

  const handleClickOpen = () => {
    setSuccessMsg('');
    setErrorMsg('');
    setOpenErrorMsg(false);
    setOpenSuccessMsg(false);
    setOpen(true);
    setEmails([]);
    setAccess('CAN_VIEW');
  };

  const handleClose = () => {
    setSuccessMsg('');
    setErrorMsg('');
    setOpenErrorMsg(false);
    setOpenSuccessMsg(false);
    setOpen(false);
    if (props.page === 'Client_Setting' && !props.refreshFlag) {
      props.setRefreshFlag(true);
    }
  };

  const removeEmail = (removeIndex) => {
    setEmails(emails.filter((_, index) => index !== removeIndex));
  };

  const enteringData = (data) => {
    setEnteredData(data.target.value);
  };

  const accessChange = (e) => {
    setAccess(e.target.value);
  };

  const handleAddEmail = (e) => {
    if(e.key==='Enter' || e.key===',' || e.key===' '){
      e.preventDefault();
      if(enteredData.trim()){
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!emailPattern.test(enteredData.trim())){
          setErrorMsg(enteredData.trim()+": please provide correct email address.");
          setOpenErrorMsg(true);
        }else{
          setEmails([...emails, enteredData.trim()]);
          setOpenErrorMsg(false);
          setErrorMsg('');
          setEnteredData('');
        }
      }
    }
  };

  const inviteUser = async () => {
    setProg(false);
    setSuccessMsg('');
    setErrorMsg('');
    const url = '/inviteUserBoards';
    axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;

    // Send email invitations
    for (const email of emails) {
      try {
        const res = await axios.post(url, {
          boardUserId: props.boardUserId,
          clientId: allBoards.boards[selectedBoard]._id,
          boardId: props.boardId,
          email,
          firstName: user.firstName,
          lastName: user.lastName,
          boardName: allBoards.boards[selectedBoard].boardName,
          companyName: state.companyName,
          access,
        });
        setSuccessMsg((prevMsg) => `${prevMsg}\n${email}: ${res.data.message}`);
      } catch (err) {
        setErrorMsg((prevMsg) =>
          prevMsg + '\n' + email + ': ' + (err.response?.data?.message || err.message)
        );
      }
    }

    setOpenSuccessMsg(true);
    setOpenErrorMsg(true);
    setProg(true);
    setInviteRefresh(true);
    setEmails([]);
    setAccess('CAN_VIEW');
  };

  const RenderTeam = (teamMembers) => (
    <Box>
      {teamMembers.map((members, i) => (
        <Card
        >
          <Box>
            <img
              style={{
                width: '2.5rem',
                height: '2.5rem',
                borderRadius: '50%',
                marginRight: '2.5rem',
              }}
              src={members && members.profile ? `${members.profile}` : Profile}
            />
            <Button
              onClick={() => { setEmails([...emails, members.email]); }}
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
                margin: 0,
                color: 'inherit',
                font: 'inherit',
                textAlign: 'left',
                cursor: 'pointer',
              }}
            >
              <Typography>
                {members.firstName && members.lastName && (
                  <>
                    {members.firstName + ' ' + members.lastName}
                  </>
                )}
                  <>
                    {' '} {members.email}
                  </>
              </Typography>
            </Button>
          </Box>
        </Card>
      ))}
    </Box>
  );

  return (
    <Root>
     {props.page && props.page === "Client_Performance" && (
          <Button 
            variant="text" 
            className="fourth-step"
            onClick={handleClickOpen}
            sx={{
              backgroundColor: props.disabled ? 'grey' : '',
              color: props.disabled ? 'lightgrey' : '',
              textTransform: 'none',
            }}
            disabled={props.disabled}
            >
              <ShareIcon />
              <span style={{ marginLeft: "5px" }}>
              Share
              </span>
          </Button>
     )}
      {props.page && props.page === "Client_Setting" && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button 
            variant="contained" 
            onClick={handleClickOpen}
            sx={{
              backgroundColor: props.disabled ? 'grey' : '#F8FAFC',
              color: props.disabled ? 'lightgrey' : 'black',
              borderRadius: '10px',
            }}
            disabled={props.disabled}
            >
              <ShareIcon />
              <span style={{ marginLeft: "5px" }}>
              Share
              </span>
          </Button>
        </div>
     )}

      <Dialog onClose={handleClose} open={open} maxWidth="xl">
        <CustomDialogTitle onClose={handleClose}>Share Project</CustomDialogTitle>
        {successMsg && (
          <Alerts msg={successMsg} type="success" openMsg={openSuccessMsg} setOpenMsg={setOpenSuccessMsg} />
        )}
        {errorMsg && (
          <Alerts msg={errorMsg} type="error" openMsg={openErrorMsg} setOpenMsg={setOpenErrorMsg} />
        )}

        <DialogContent dividers>
          <div style={{ display: 'flex', alignItems: 'center', width: '600px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', flex: 1, width: '70%', border: '1px solid #ccc', borderRadius: '4px', padding: '5px' }}>
              {emails.map((email, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '5px', borderRadius: '16px', backgroundColor: '#000000', color: '#fff', marginRight: '8px', marginBottom: '8px' }}>
                  <span>{email}</span>
                  <span onClick={() => removeEmail(index)} style={{ marginLeft: '8px', cursor: 'pointer', fontWeight: 'bold', color: '#ff0000' }}>
                    &times;
                  </span>
                </div>
              ))}
              <input type="text" value={enteredData} onChange={enteringData} onKeyDown={handleAddEmail} placeholder="Enter emails" style={{ border: 'none', outline: 'none', flex: '1', minWidth: '200px' }} />
            </div>
            <Select style={{ marginTop: '10px', marginLeft: '20px' }} variant="standard" value={access} label="Access" onChange={accessChange} disableUnderline>
              {totalAvailable > 0 && <MenuItem value="CAN_MODIFY">Can Edit</MenuItem>}
              {totalAvailable > 0 && <ListSubheader>Can edit but not share with others</ListSubheader>}
              <MenuItem value="CAN_VIEW">Can View</MenuItem>
              <ListSubheader>Cannot edit or share with others.</ListSubheader>
            </Select>
            <Button variant="contained" color="primary" onClick={inviteUser}>
              Invite
            </Button>
          </div>
        </DialogContent>
        {teamMembers && teamMembers.length > 0 && (
          <DialogContent dividers>
            <h3>Team Members</h3>
            {RenderTeam(teamMembers)}
          </DialogContent>
        )}
        <DialogContent dividers>
          <InviteView setTotalAvailable={setTotalAvailable} boardId={props.boardId} disabled={!props.disabled} inviteRefresh={inviteRefresh} setinviteRefresh={setInviteRefresh} />
        </DialogContent>
      </Dialog>
    </Root>
  );
};

export default InviteBoard;