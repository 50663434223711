/* eslint-disable */
import moment from 'moment';
import BS from '../../assets/BS.svg';
import CF from '../../assets/CF.svg';
import PL from '../../assets/PL.svg';



export function extractPermission(str) {
    const permission = str.split('|')[0].trim();
     
    return permission;
}

export function extractId(str) {
    const permission = str.split('|')[1].trim();
    return permission;
}

export const countMatchingOwners = (allBoards) => {
    const userId = allBoards.userId;
    const boards = allBoards.boards;
    let matchingOwnersCount = 0;

    if (boards && boards.length > 0) {

        boards.forEach(board => {
            if (board.owner === userId) {
                matchingOwnersCount++;
            }
        });
    }

    // 
    return matchingOwnersCount;
}

export const setListItemsLine = (allData, data, formulas, headers, preFiltered) => {
    let filteredArr2 = allData

    if (Array.isArray(filteredArr2)) {
        if (!preFiltered && data && data.length > 0) {
            filteredArr2 = allData.filter(item2 => {
                let match = data.find(item1 => item1.name === item2.name && item1.hidden === false);
                return Boolean(match);  
              });
        }

        for (const item of formulas) {
            let tempItem = {
                "name": item.name,
                "title": item.name,
                "header": true,
                "headerVal": item.name,
                "amount": item.amounts ?? [],
                "totalAmount": item.totalAmount
            }
            filteredArr2.push(tempItem);

        }

        const transformedData = [headers];

        filteredArr2.forEach((entry, index) => {
            const row = [entry.name, ...entry.amount.map(parseFloat)];
            transformedData.push(row);
        });

        const flippedData = transformedData[0].map((_, columnIndex) => {
            if (columnIndex === 0) {
                return transformedData.map(row => row[columnIndex]);
            } else {
                return transformedData.map((row, rowIndex) => {
                    if (rowIndex > 0) {
                        return parseFloat(row[columnIndex]);
                    } else {
                        return row[columnIndex];
                    }
                });
            }
        });

        return flippedData;
    } else {
        return [];
    }
}

export const handleDateChangeFilterHelper = (name) => {
    let startDate = "";
    let endDate = "";

    // Set the date range based on the button clicked
    switch (name) {
        case "today":
            startDate = moment().format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
            break;
        case "7D":
            startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
            break;
        case "1M":
            startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
            break;
        case "1Q":
            startDate = moment().subtract(3, "months").format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
            break;
        case "2Q":
            startDate = moment().subtract(6, "months").format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
            break;
        case "4Q":
            startDate = moment().subtract(1, "years").format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
            break;
        case "1Y":
            startDate = moment().subtract(1, "years").format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
            break;
        default:
            // Default date range if no button is selected
            startDate = "";
            endDate = "";
    }
    return `${startDate} - ${endDate}`;
};
// export const getDateRange = () => {
//     const currentDate = new Date();
//     const currentYear = currentDate.getFullYear();
//     const startDate = new Date(currentYear, 0, 1); // January 1st of current year
//     const formattedStartDate = startDate.toISOString().slice(0, 10); // format as yyyy-mm-dd
//     const formattedEndDate = currentDate.toISOString().slice(0, 10); // format as yyyy-mm-dd
//     return `${formattedStartDate} - ${formattedEndDate}`;
// }

export const getDateRangeOld = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Calculate the previous month's year and month
    const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;

    // Set the start date as the 1st of the previous month
    const startDate = new Date(previousYear, previousMonth, 1);

    const formattedStartDate = startDate.toISOString().slice(0, 10); // format as yyyy-mm-dd
    const formattedEndDate = currentDate.toISOString().slice(0, 10); // format as yyyy-mm-dd

    return `${formattedStartDate} - ${formattedEndDate}`;
};

export const getDateRange = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    // Calculate the one year back year and month
    const oneYearBackYear = currentYear - 1;
    const oneYearBackMonth = currentMonth;

    // Set the start date as the same day and month one year back
    const startDate = new Date(oneYearBackYear, oneYearBackMonth, currentDay);

    const formattedStartDate = startDate.toISOString().slice(0, 10); // format as yyyy-mm-dd
    const formattedEndDate = currentDate.toISOString().slice(0, 10); // format as yyyy-mm-dd
    return `${formattedStartDate} - ${formattedEndDate}`;
};



export const convertArray = (inputArr) => {
    return inputArr.map((inputObj) => {
        const { name, formula, amounts, totalAmount } = inputObj;

        const newObj = {
            "name": name,
            "title": name,
            "amount": amounts,
            "totalAmount": totalAmount,
            "header": false,
            "items": []
        };

        return newObj;
    });
};


export const doFormulas = (arr1, arr2) => {
    if (arr1 && arr1.length > 0) {
        const variables = {};
        arr1.forEach((item) => {
            variables[item.id] = item.object.amount.map(Number);
        });

        const getAmounts = (formula) => {
            const regex = /[A-Z]+|[+\-*/]|\d+/g;
            const parts = formula.match(regex);
            if (parts) {
                return parts.map((part) => {
                    if (part in variables) {
                        return variables[part];
                    } else if (/[+\-*/]/.test(part)) {
                        return part; // Return arithmetic operators unchanged
                    } else {
                        return parseFloat(part);
                    }
                });
            } else {
                return [];
            }
        };

        const evaluateExpression = (expression, amountsArray) => {
            const calculate = (a, operator, b) => {
                switch (operator) {
                    case '+':
                        return a + b;
                    case '-':
                        return a - b;
                    case '*':
                        return a * b;
                    case '/':
                        return a / b;
                    default:
                        return 0;
                }
            };

            const operatorStack = [];
            const numberStack = [];

            for (const part of amountsArray) {
                if (typeof part === 'number') {
                    numberStack.push(part);
                } else if (typeof part === 'string' && /[+\-*/]/.test(part)) {
                    while (operatorStack.length > 0 && operatorStack[operatorStack.length - 1] !== '(') {
                        const operator = operatorStack.pop();
                        const b = numberStack.pop();
                        const a = numberStack.pop();
                        const result = calculate(a, operator, b);
                        numberStack.push(result);
                    }
                    operatorStack.push(part);
                } else if (part === '(') {
                    operatorStack.push(part);
                } else if (part === ')') {
                    while (operatorStack.length > 0 && operatorStack[operatorStack.length - 1] !== '(') {
                        const operator = operatorStack.pop();
                        const b = numberStack.pop();
                        const a = numberStack.pop();
                        const result = calculate(a, operator, b);
                        numberStack.push(result);
                    }
                    operatorStack.pop(); // Pop the '(' from the operator stack
                }
            }

            while (operatorStack.length > 0) {
                const operator = operatorStack.pop();
                const b = numberStack.pop();
                const a = numberStack.pop();
                const result = calculate(a, operator, b);
                numberStack.push(result);
            }



            return numberStack[0];
        };



        arr2.forEach((item) => {
            const amountsArray = getAmounts(item.formula);
            item.amounts = Array(variables['A'].length).fill(0); // Initialize amounts array with zeros

            if (amountsArray.length === 1 && typeof amountsArray[0] === 'number') {
                // Case when formula is just a single number (e.g., "A/2")
                item.amounts = amountsArray[0];
            } else {
                for (let i = 0; i < variables['A'].length; i++) {
                    for (let j = 0; j < amountsArray[0].length; j = j + 1) {
                        let currentAmounts = [];
                        ;

                        for (const item of amountsArray) {
                            // ;
                            if (Array.isArray(item)) {
                                currentAmounts.push(item[j]);
                            } else {
                                if (item) {
                                    currentAmounts.push(item);

                                } else {
                                    currentAmounts.push(0);

                                }
                            }
                        }
                        ;

                        const evaluatedAmount = evaluateExpression(item.formula, currentAmounts);
                        item.amounts[j] = Number(evaluatedAmount.toFixed(2)); // Fix the value to 2 decimal points
                    }
                }
            }
            item.totalAmount = item.amounts.reduce((acc, val) => acc + val, 0);
        });

    }


    return arr2;
};




// export const formatDateRange = (dateRange) => {
//     const [startDate, endDate] = dateRange.split(' - ');
//     const start = new Date(startDate);
//     const end = new Date(endDate);
//     const startMonth = start.toLocaleString('en-US', { month: 'short' });
//     const startYear = start.getFullYear();
//     const endMonth = end.toLocaleString('en-US', { month: 'short' });
//     const endYear = end.getFullYear();
//     return `${startMonth} ${startYear} to ${endMonth} ${endYear}`;
// }

export const formatDateRange = (dateRange) => {
    try {
        if (!dateRange || typeof dateRange !== "string" || !dateRange.includes(" - ")) {
            console.error("Invalid dateRange:", dateRange);
            return "Invalid Date Range";
        }

        const [startDate, endDate] = dateRange.split(" - ").map(date => date.trim());
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (isNaN(start.getTime()) || isNaN(end.getTime())) {
            console.error("Invalid date:", startDate, endDate);
            return "Invalid Date Range";
        }

        const startMonth = start.toLocaleString("en-US", { month: "short" });
        const startYear = start.getFullYear();
        const endMonth = end.toLocaleString("en-US", { month: "short" });
        const endYear = end.getFullYear();

        return `${startMonth} ${startYear} to ${endMonth} ${endYear}`;
    } catch (error) {
        console.error("Error formatting date range:", error);
        return "Invalid Date Range";
    }
};

// export const formatDateRange = (dateRange) => {
//     console.log("dateRange received:", dateRange); // Debugging log

//     if (!dateRange || typeof dateRange !== 'string' || !dateRange.includes(' - ')) {
//         console.error("Invalid dateRange format:", dateRange);
//         return 'Invalid Date Range'; 
//     }

//     const [startDate, endDate] = dateRange.split(' - ').map(date => date.trim());

//     const start = new Date(startDate);
//     const end = new Date(endDate);

//     console.log("Parsed Dates:", start, end); // Debugging log

//     if (isNaN(start.getTime()) || isNaN(end.getTime())) {
//         console.error("Invalid Date Detected:", startDate, endDate);
//         return 'Invalid Date Range';
//     }

//     const startMonth = start.toLocaleString('en-US', { month: 'short' });
//     const startYear = start.getFullYear();
//     const endMonth = end.toLocaleString('en-US', { month: 'short' });
//     const endYear = end.getFullYear();

//     return `${startMonth} ${startYear} to ${endMonth} ${endYear}`;
// };

export const getIconType = (value) => {
    if (value === "Balance Sheet") {
        return BS;
    } else if (value === "Cash Flow") {
        return CF;
    } else if (value === "Income Statement") {
        return PL;
    } else {
        return "";
    }
}

export const setListItemsParsed = (allData, data, formulas) => {
    let dataTable = allData;
    const filteredArr2 = allData.filter(item2 => {
        let match = data.find(item1 => item1.name === item2.name && item1.hidden === false);
        return Boolean(match);  
      });

    const filteredArr3 = data.filter(item2 => {
        const matchedItem = allData.find(item1 => item1.name === item2.name);
        if (matchedItem) {
            // Assign the matchedItem to the 'object' field of item2
            item2.object = matchedItem;
            item2.object.amounts = item2.object.amount
            return true;
        } else {
            return false;
        }
    });

    let temp = doFormulas(filteredArr3, formulas);

    let newArray = [
        ["Category", "Amount"],
    ];
    for (let i = 0; i < filteredArr2.length; i++) {
        const tempArray = [filteredArr2[i].name, Math.abs(filteredArr2[i].totalAmount)];
        newArray.push(tempArray);
    }
    let createFormulas = doFormulas(data, formulas);
    const formulasMapped = createFormulas.map(item => [item.name, item.totalAmount]);
    newArray = [...newArray, ...formulasMapped];
    return newArray;

}





////////////
export const content = [
    {
        "title": "TOTAL ASSETS",

        "name": "TOTAL ASSETS",
        "amount": [
            "23436.29"
        ],
        "totalAmount": "23436.29",
        "header": true
    },
    {
        "title": "Total Current Assets",
        "name": "Total Current Assets",
        "amount": [
            "9941.29"
        ],
        "totalAmount": "9941.29",
        "header": true
    },
    {
        "name": "Total Bank Accounts",
        "amount": [
            "2001.00"
        ],
        "totalAmount": "2001.00",
        "header": true
    },
    {
        "name": "Checking",
        "header": false,
        "headerVal": "Total Bank Accounts",
        "amount": [
            "1201.00"
        ],
        "totalAmount": "1201.00"
    },
    {
        "name": "Savings",
        "header": false,
        "headerVal": "Total Bank Accounts",
        "amount": [
            "800.00"
        ],
        "totalAmount": "800.00"
    },
    {
        "name": "Total Accounts Receivable",
        "amount": [
            "5281.52"
        ],
        "totalAmount": "5281.52",
        "header": true
    },
    {
        "name": "Accounts Receivable (A/R)",
        "header": false,
        "headerVal": "Total Accounts Receivable",
        "amount": [
            "5281.52"
        ],
        "totalAmount": "5281.52"
    },
    {
        "name": "Total Other Current Assets",
        "amount": [
            "2658.77"
        ],
        "totalAmount": "2658.77",
        "header": true
    },
    {
        "name": "Inventory Asset",
        "header": false,
        "headerVal": "Total Other Current Assets",
        "amount": [
            "596.25"
        ],
        "totalAmount": "596.25"
    },
    {
        "name": "Undeposited Funds",
        "header": false,
        "headerVal": "Total Other Current Assets",
        "amount": [
            "2062.52"
        ],
        "totalAmount": "2062.52"
    },
    {
        "name": "Total Fixed Assets",
        "amount": [
            "13495.00"
        ],
        "totalAmount": "13495.00",
        "header": true
    },
    {
        "name": "Total Truck",
        "amount": [
            "13495.00"
        ],
        "totalAmount": "13495.00",
        "header": true
    },
    {
        "name": "Original Cost",
        "header": false,
        "headerVal": "Total Truck",
        "amount": [
            "13495.00"
        ],
        "totalAmount": "13495.00"
    },
    {
        "name": "TOTAL LIABILITIES AND EQUITY",
        "amount": [
            "23436.29"
        ],
        "totalAmount": "23436.29",
        "header": true
    },
    {
        "name": "Total Liabilities",
        "amount": [
            "31131.33"
        ],
        "totalAmount": "31131.33",
        "header": true
    },
    {
        "name": "Total Current Liabilities",
        "amount": [
            "6131.33"
        ],
        "totalAmount": "6131.33",
        "header": true
    },
    {
        "name": "Total Accounts Payable",
        "amount": [
            "1602.67"
        ],
        "totalAmount": "1602.67",
        "header": true
    },
    {
        "name": "Accounts Payable (A/P)",
        "header": false,
        "headerVal": "Total Accounts Payable",
        "amount": [
            "1602.67"
        ],
        "totalAmount": "1602.67"
    },
    {
        "name": "Total Credit Cards",
        "amount": [
            "157.72"
        ],
        "totalAmount": "157.72",
        "header": true
    },
    {
        "name": "Mastercard",
        "header": false,
        "headerVal": "Total Credit Cards",
        "amount": [
            "157.72"
        ],
        "totalAmount": "157.72"
    },
    {
        "name": "Total Other Current Liabilities",
        "amount": [
            "4370.94"
        ],
        "totalAmount": "4370.94",
        "header": true
    },
    {
        "name": "Arizona Dept. of Revenue Payable",
        "header": false,
        "headerVal": "Total Other Current Liabilities",
        "amount": [
            "0.00"
        ],
        "totalAmount": "0.00"
    },
    {
        "name": "Board of Equalization Payable",
        "header": false,
        "headerVal": "Total Other Current Liabilities",
        "amount": [
            "370.94"
        ],
        "totalAmount": "370.94"
    },
    {
        "name": "Loan Payable",
        "header": false,
        "headerVal": "Total Other Current Liabilities",
        "amount": [
            "4000.00"
        ],
        "totalAmount": "4000.00"
    },
    {
        "name": "Total Long-Term Liabilities",
        "amount": [
            "25000.00"
        ],
        "totalAmount": "25000.00",
        "header": true
    },
    {
        "name": "Notes Payable",
        "header": false,
        "headerVal": "Total Long-Term Liabilities",
        "amount": [
            "25000.00"
        ],
        "totalAmount": "25000.00"
    },
    {
        "name": "Total Equity",
        "amount": [
            "-7695.04"
        ],
        "totalAmount": "-7695.04",
        "header": true
    },
    {
        "name": "Opening Balance Equity",
        "header": false,
        "headerVal": "Total Equity",
        "amount": [
            "-9337.50"
        ],
        "totalAmount": "-9337.50"
    },
    {
        "name": "Retained Earnings",
        "header": false,
        "headerVal": "Total Equity",
        "amount": [
            "1642.46"
        ],
        "totalAmount": "1642.46"
    },
    {
        "name": "Net Income",
        "header": false,
        "headerVal": "Total Equity",
        "amount": [
            0
        ],
        "totalAmount": 0
    }
]


export const dataHelper =
{
    "_id": "640fb291c0714f384d0358f0",
    "boardId": "640f8277fae7d50a9a941b28",
    "data": {
        "index": 0,
        "boardView": "Balance Sheet",
        "boardType": "table",
        "boardData": [
            {
                "id": "A",
                "name": "TOTAL ASSETS",
                "object": {
                    "name": "TOTAL ASSETS",
                    "title": "TOTAL ASSETS",
                    "amount": [
                        0,
                        "23436.29",
                        "23436.29"
                    ],
                    "totalAmount": 46872.58,
                    "header": true,
                    "items": []
                }
            },
            {
                "id": "B",
                "name": "Total Bank Accounts",
                "object": {
                    "name": "Total Bank Accounts",
                    "title": "Total Bank Accounts",
                    "amount": [
                        0,
                        "2001.00",
                        "2001.00"
                    ],
                    "totalAmount": 4002,
                    "header": true,
                    "items": [
                        {
                            "name": "Checking",
                            "title": "Checking",
                            "header": false,
                            "headerVal": "Total Bank Accounts",
                            "amount": [
                                0,
                                "1201.00",
                                "1201.00"
                            ],
                            "totalAmount": "1201.00"
                        },
                        {
                            "name": "Savings",
                            "title": "Savings",
                            "header": false,
                            "headerVal": "Total Bank Accounts",
                            "amount": [
                                0,
                                "800.00",
                                "800.00"
                            ],
                            "totalAmount": "800.00"
                        }
                    ]
                }
            },
            {
                "id": "C",
                "name": "Total Other Current Assets",
                "object": {
                    "name": "Total Other Current Assets",
                    "title": "Total Other Current Assets",
                    "amount": [
                        0,
                        "2658.77",
                        "2658.77"
                    ],
                    "totalAmount": 5317.54,
                    "header": true,
                    "items": [
                        {
                            "name": "Inventory Asset",
                            "title": "Inventory Asset",
                            "header": false,
                            "headerVal": "Total Other Current Assets",
                            "amount": [
                                0,
                                "596.25",
                                "596.25"
                            ],
                            "totalAmount": "596.25"
                        },
                        {
                            "name": "Undeposited Funds",
                            "title": "Undeposited Funds",
                            "header": false,
                            "headerVal": "Total Other Current Assets",
                            "amount": [
                                0,
                                "2062.52",
                                "2062.52"
                            ],
                            "totalAmount": "2062.52"
                        }
                    ]
                }
            },
            {
                "id": "D",
                "name": "Total Current Liabilities",
                "object": {
                    "name": "Total Current Liabilities",
                    "title": "Total Current Liabilities",
                    "amount": [
                        0,
                        "6131.33",
                        "6131.33"
                    ],
                    "totalAmount": 12262.66,
                    "header": true,
                    "items": []
                }
            },
            {
                "id": "E",
                "name": "Total Other Current Liabilities",
                "object": {
                    "name": "Total Other Current Liabilities",
                    "title": "Total Other Current Liabilities",
                    "amount": [
                        0,
                        "4370.94",
                        "4370.94"
                    ],
                    "totalAmount": 8741.88,
                    "header": true,
                    "items": [
                        {
                            "name": "Arizona Dept. of Revenue Payable",
                            "title": "Arizona Dept. of Revenue Payable",
                            "header": false,
                            "headerVal": "Total Other Current Liabilities",
                            "amount": [
                                0,
                                "0.00",
                                "0.00"
                            ],
                            "totalAmount": "0.00"
                        },
                        {
                            "name": "Board of Equalization Payable",
                            "title": "Board of Equalization Payable",
                            "header": false,
                            "headerVal": "Total Other Current Liabilities",
                            "amount": [
                                0,
                                "370.94",
                                "370.94"
                            ],
                            "totalAmount": "370.94"
                        },
                        {
                            "name": "Loan Payable",
                            "title": "Loan Payable",
                            "header": false,
                            "headerVal": "Total Other Current Liabilities",
                            "amount": [
                                0,
                                "4000.00",
                                "4000.00"
                            ],
                            "totalAmount": "4000.00"
                        }
                    ]
                }
            }
        ],
        "boardFormulas": []
    }
}
