/* eslint-disable */
import BoltIcon from "@mui/icons-material/Bolt";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/context";
import { addNewBoard } from "../../context/intuit-service";
import { extractId } from "../ClientPerformance/helpers";
import "./DtGpt.css";
import { sendMessageToGpt } from "./Gpt";
import { formatMessageContent } from "./AiFormatMessage";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { CircularProgress } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import File from "./File";
// csv parser
import Papa from "papaparse";
import OpenAI from "openai";
import { queryEmbeddings } from "./queryEmbeddings";
import { sendToGPT } from "./Gpt";
import { retrieveStoredEmbeddings, retrieveStoredData  } from "./helpers";
import ChartComponent from "../ClientPerformance/ChartComponent";
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Badge, { badgeClasses } from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import IntegrationsButtonAddBoard from "../Projects/IntegrationsButtonAddBoard";


// excel parser
// import xlsx from 'node-xlsx';
import * as XLSX from "xlsx";
import axios from "../../context/axios";
import { ArrowBack, BackHandSharp, Palette } from "@mui/icons-material";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CartBadge = styled(Badge)`
  & .${badgeClasses.badge} {
    // top: -12px;
    // right: -2px;
  }
`;

function DtGpt({ data, onClose, panelOpen, setPanelOpen }) {
  const {
    state: {
      user,
      switchUser,
      isAuthenticated,
      access_token,
      purchasing,
      projects,
      selectedBoard,
      allBoards,
      selectedTab,
      gptData,
      data_change,
      uploadedFiles,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const [assistantData, setAssistantData] = useState(null);

  useEffect(() => {
    // console.log("useEffect", data, assistantData, gptData)
    if (assistantData) {
      dispatch({
        type: "SET_GPT_DATA",
        payload: assistantData,
      });

    return;
  }
    console.log(data);
    dispatch({
      type: "SET_GPT_DATA",
      payload: data,
    });

  }, [data && data.length > 0, assistantData]);

  const [step, setStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [fileSource, setFileSource] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [source, setSource] = useState("");
  // const [embeddingData, setEmbeddingData] = useState(null);

  const [graphData, setGraphData] = useState([]);
  const [chartConfig, setChartConfig] = useState(null);
  const [title, setTitle] = useState('')
  const [textResponse, setTextResponse] = useState('');
  const [systemMessage, setSystemMessage] = useState('');

  const [storedEmbeddings, setStoredEmbeddings] = useState([]);
  const [storedData, setStoredData] = useState([]);
  const [queryResults, setQueryResults] = useState([]);
  const [embeddingsDatabase, setEmbeddingDatabase] = useState([])

  const [response, setResponse] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [scrollContainer, setScrollContainer] = useState(null); // State to hold the chat content container
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);
  // const [panelOpen, setPanelOpen] = useState(true);
  const [ai_loaded, setAI_Loaded] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchFiles, setFetchFiles] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadingUserFiles, setUploadingUserFiles] = useState(false);

  const inputRef = useRef(null);

  // State to store embeddings and data
  const [embeddings, setEmbeddings] = useState([]);
  const [dataObjects, setDataObjects] = useState([]);
  const [error, setError] = useState(null); // Error state

  // Fetch embeddings dynamically
  const fetchEmbeddings = async () => {
    return embeddings;
  };

  // Fetch data objects dynamically
  const fetchDataObjects = async () => {
    return dataObjects;
  };

  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]); // Runs whenever messages update

  useEffect(() => {
    if (
      chartConfig &&
      chartConfig === 0
    ) {
      setChartConfig(null);
    } else if (chartConfig && chartConfig > 0 ) {
        setChartConfig(true);
    } else if (textResponse && textResponse > 0){
      setTextResponse(true);
    }
  });

  // Effect to load data on mount
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      // setEmbeddings(embeddings);
      setDataObjects(dataObjects);
  };

    loadData();
  }, []); // Empty dependency array ensures this runs once on mount


  useEffect(() => {
    if (loading) {
       
    } else {
       
    }
  }, [loading]);

  // useEffect(() => {
     
  // }, [messages]);

  useEffect(() => {
    if (uploadedFiles.length === 0) {
      setStep(1);
      setSelectedFiles([]);
      setUploadedFileName(null);
    }
  }, [uploadedFiles]);

  useEffect(() => {
       
    if (chartConfig && chartConfig.options && chartConfig.options.plugins && chartConfig.options.plugins.title && chartConfig.options.plugins.title.text) {
      setTitle(chartConfig.options.plugins.title.text);
    }
    else if (chartConfig && chartConfig.data && chartConfig.data.datasets && chartConfig.data.datasets[0] && chartConfig.data.datasets[0].label) {
      setTitle(chartConfig.data.datasets[0].label);
    }
    else {
      setMessages(messages);
    }
  }, [chartConfig])


  // check to see if there are already uploaded files in the user's account
  useEffect(() => {
    const fetchFiles = async () => {
      try {
         
        const response = await axios.get("/retrieveFiles");
        const fetchedFiles = response.data.files || [];

        dispatch({
          type: "SET_UPLOADED_FILES",
          payload: fetchedFiles,
        });

         
      } catch (error) {
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "Unknown error occurred.";
        console.error("Error uploading files: ", errorMessage);
        setUploadingUserFiles(false);
      }
    };

    fetchFiles();
  }, [dispatch]); // Empty dependency array ensures this runs once

  useEffect(() => {
     
  }, [conversationHistory]);

  useEffect(() => {
     
  }, [storedData]);


  const handleOptionSelect = async (option) => {
    setLoading(true);
    setStep(2);

    try {
        if (option === "P&L") {
            await connectQuickbooks(
                data,
                "Income Statement, Balance Sheet, CashFlow"
            );
        } else if (option === "Balance") {
            await connectQuickbooks(
                data,
                "Income Statement, Balance Sheet, CashFlow"
            );
        } else if (option === "CashFlow") {
            await connectQuickbooks(
                data,
                "Income Statement, Balance Sheet, CashFlow"
            );
        } else {
            console.warn("Unknown option selected");
        }

        setSelectedOption(option);

        // Set the source only after validating the option
        setSource("option");
    } catch (error) {
        console.error("Error handling option selection:", error);
    } finally {
        setLoading(false);
    }
};

useEffect(() => {
  if (selectedOption) {
      // Set source as "option" if an option is selected
      setSource("option");
  } else if (fileSource && Object.keys(fileSource).length > 0) {
      // Set source as "fileSource" if valid file data exists
      setSource("fileSource");
  } else {
      // Reset source if no valid triggers
      setSource(null);
  }
}, [selectedOption, fileSource]);


  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const copyToClipboard = async () => {
    if (messages.length === 0) {
      return;
    }

    const lastMessage = messages[messages.length - 1].content;

    if (lastMessage) {
      try {
        await navigator.clipboard.writeText(lastMessage);
      } catch (error) {
        console.error("Failed to copy:", error);
      }
    } else {
    }
  };

  function extractFirstHeaderAndCleanMessage(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const header = doc.querySelector("h1, h2, h3, h4, h5, h6");

    let headerText = "";
    if (header) {
      headerText = header.textContent.trim();
      const nextSibling = header.nextSibling;
      header.remove();

      let currentNode = nextSibling;
      while (
        currentNode &&
        currentNode.nodeType === Node.TEXT_NODE &&
        /^\s*$/.test(currentNode.textContent)
      ) {
        const nodeToRemove = currentNode;
        currentNode = currentNode.nextSibling;
        nodeToRemove.remove();
      }
    }

    const modifiedContent = doc.body.innerHTML.trim();
    return { header: headerText, modifiedContent };
  }


  const addToBoard = async () => {
    // Check if the last message or second-to-last message has a chartConfig
    const lastMessage = messages[messages.length - 1];
    const secondLastMessage = messages[messages.length - 2];

    const alternateTitle = messages[messages.length - 2].role === "assistant" ? messages[messages.length - 2].content : null;

    let targetMessage = lastMessage;

    // If last message has chartConfig, check second last message
    if (lastMessage.chartConfig && secondLastMessage?.role === "assistant") {
        targetMessage = secondLastMessage;
    } else if (lastMessage.chartConfig) {
        // If secondLastMessage is from user, we shouldn't use it
        console.warn("Skipping second last message as it's from user.");
        return; // Exit early to avoid adding an invalid message
    }

    // Ensure we have a valid target message
    if (typeof targetMessage?.content !== "string" || targetMessage.content.trim() === "") {
        console.error("Target message is empty or not a valid string.");
        return;
    }

    const { header: boardTitle, modifiedContent: cleanedLastMessage } =
        extractFirstHeaderAndCleanMessage(targetMessage.content);

    let boardInfo = {
        clientId: allBoards.boards[selectedBoard]._id,
        boardId: allBoards._id,
        tabId: allBoards.boards[selectedBoard].tabs[selectedTab]._id, // Include tabId in the payload
        data: {
            index: allBoards.boards[selectedBoard].tabs[selectedTab].boardData
                ? allBoards.boards[selectedBoard].tabs[selectedTab].boardData.length
                : 0,
            boardView: "Text",
            boardType: "Text",
            title: boardTitle === "" ? "" : boardTitle,
            boardData: {
                text: cleanedLastMessage,
            },
        },
    };

    if (allBoards.boards[selectedBoard].access) {
        boardInfo.boardId = extractId(allBoards.boards[selectedBoard].access);
    }

    let addNewBoardResponse = await addNewBoard(boardInfo);

    dispatch({
        type: "SET_ALLBOARDS",
        payload: addNewBoardResponse.data.data,
    });
};
  
  const addToBoardGraph = async () => {
       
       
       
    const index = allBoards.boards[selectedBoard].tabs[selectedTab].boardData.length;
    const data = {
      boardType: "graph",
      title: title ? title : chartConfig.data.datasets[0].title,
      boardView: "graph",
      index: index,
      boardData: [
        {
          config: chartConfig,
          query: userInput
        },
      ],
    };
    // boardId represents all the boards (all the clients we have), client id is the specific board or client we're on
    const boardSchema = {
      boardId: allBoards._id,
      clientId: allBoards.boards[selectedBoard]._id,
      boardName: allBoards.boardName,
      tabId: allBoards.boards[selectedBoard].tabs[selectedTab]._id,
      data: data,
    }

    const newClients = await addNewBoard(boardSchema);
     

    dispatch({
      type: 'SET_ALLBOARDS',
      payload: newClients.data.data
    });

  }


  const addFile = async (files) => {
    try {
      setUploadingUserFiles(true);
      const uploadedFilesArray = [...uploadedFiles]; // Clone existing uploadedFiles

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Read file content
        const reader = new FileReader();
        const fileContent = await new Promise((resolve, reject) => {
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = (e) => reject(e);
          reader.readAsText(file);
        });

        const formData = new FormData();
        formData.append("file", file);
        formData.append("email", user.email);

        const response = await axios.post("/addFile", formData);
        const uploadedFile = response.data.file;

        if (uploadedFile) {
          uploadedFile.content = fileContent;

          if (!uploadedFile._id) {
            console.error("Error: file _id not found:", uploadedFile);
          } else {
             
          }

          uploadedFilesArray.push(uploadedFile);
        } else {
           
        }
      }

      // 
      // Update the state with all uploaded files at once
      dispatch({
        type: "SET_UPLOADED_FILES",
        payload: uploadedFilesArray,
      });

      setUploadingUserFiles(false);
       
    } catch (error) {
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "Unknown error occurred.";
        console.error("Error uploading files: ", errorMessage);
      setUploadingUserFiles(false);
    }
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      // Filter out files that are not .csv
      const csvFiles = Array.from(files).filter((file) =>
        file.name.endsWith(".csv")
      );
      if (csvFiles.length == 1) {
        addFile(csvFiles);
        setUploadedFileName(`${csvFiles[0].name}`);
      } else if (csvFiles.length > 1) {
        addFile(csvFiles);
        setUploadedFileName(`${csvFiles.length} file(s) uploaded`);
      } else {
        alert("Please upload .csv files only.");
      }
    }
  };

  const constructPrompt = (userInput, topResults) => {
    const context = topResults
        .map((result, i) => `### Result ${i + 1}\nTitle: ${result.dataObject.title}\nContent: ${result.dataObject.content}`)
        .join("\n\n");

    return `You are a data analyst. Based on the following context, answer the query: "${userInput}".\n\nContext:\n${context}`;
};


  const connectQuickbooks = async (data, dataType) => {
    let jsonData = JSON.stringify(data);

    const prompt = `
      Here is a stringified json dataset - read and remember that data. You are an assistant to help automate CPA's tasks. 
      Always take a deep breadth, scan through the data thoroughly before returning a response. Do not rush. Do not generate a chart unless the user asks.
      Also, do not give me any explanations or formulas, simply give me the information I ask for without speaking.
      Return responses in html format. Use bullet points, sections, and paragraphs compared to tabular format. Do not apologize, do not preface, be concise. Simply give me the information exactly how I ask for. 
      If there is no data after "->" tell the user this. Please reference this data for every query.
      The user may ask you to create a graph. In that case return a JSON with the chart.js v3 requirements to graph a graph.
      Include graph data in JSON format enclosed within <graph>...</graph> tags. Use solid colors. If the response is entirely JSON, include a "graphData" key.
      Provide any graph data in JSON format enclosed within <graph> and </graph> tags. For example:
      <graph>
      {
        "graphData": {
          "type": "line",
          "data": {
            "labels": [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September"
            ],
            "datasets": [
              {
                "label": "Debt-to-Equity Ratio",
                "data": [
                  52.75,
                  43.33,
                  3.52,
                  11.68,
                  4.11,
                  64,
                  32.12,
                  51.35,
                  90.85
                ],
                "borderColor": "rgba(75, 72, 192, 1)",
                "backgroundColor": "rgba(75, 72, 192, 1)",
                "borderWidth": 1,
                "fill": false
              }
            ]
          },
          "options": {
            "maintainAspectRatio": false,
            "responsive": true,
            "scales": {
              "y": {
                "axis": "y",
                "type": "linear",
                "beginAtZero": true,
                "ticks": {
                  "minRotation": 0,
                  "maxRotation": 50,
                  "mirror": false,
                  "textStrokeWidth": 0,
                  "textStrokeColor": "",
                  "padding": 3,
                  "display": true,
                  "autoSkip": true,
                  "autoSkipPadding": 3,
                  "labelOffset": 0,
                  "align": "center",
                  "crossAlign": "near",
                  "showLabelBackdrop": false,
                  "backdropColor": "rgba(255, 255, 255, 0.75)",
                  "backdropPadding": 2,
                  "color": "#666"
                },
                "display": true,
                "offset": false,
                "reverse": false,
                "bounds": "ticks",
                "clip": true,
                "grace": 0,
                "grid": {
                  "display": true,
                  "lineWidth": 1,
                  "drawOnChartArea": true,
                  "drawTicks": true,
                  "tickLength": 8,
                  "offset": false,
                  "color": "rgba(0,0,0,0.1)"
                },
                "border": {
                  "display": true,
                  "dash": [],
                  "dashOffset": 0,
                  "width": 1,
                  "color": "rgba(0,0,0,0.1)"
                },
                "title": {
                  "display": false,
                  "text": "",
                  "padding": {
                    "top": 4,
                    "bottom": 4
                  },
                  "color": "#666"
                },
                "id": "y",
                "position": "left"
              },
              "x": {
                "axis": "x",
                "type": "category",
                "ticks": {
                  "minRotation": 0,
                  "maxRotation": 50,
                  "mirror": false,
                  "textStrokeWidth": 0,
                  "textStrokeColor": "",
                  "padding": 3,
                  "display": true,
                  "autoSkip": true,
                  "autoSkipPadding": 3,
                  "labelOffset": 0,
                  "align": "center",
                  "crossAlign": "near",
                  "showLabelBackdrop": false,
                  "backdropColor": "rgba(255, 255, 255, 0.75)",
                  "backdropPadding": 2,
                  "color": "#666"
                },
                "display": true,
                "offset": false,
                "reverse": false,
                "beginAtZero": false,
                "bounds": "ticks",
                "clip": true,
                "grid": {
                  "display": true,
                  "lineWidth": 1,
                  "drawOnChartArea": true,
                  "drawTicks": true,
                  "tickLength": 8,
                  "offset": false,
                  "color": "rgba(0,0,0,0.1)"
                },
                "border": {
                  "display": true,
                  "dash": [],
                  "dashOffset": 0,
                  "width": 1,
                  "color": "rgba(0,0,0,0.1)"
                },
                "title": {
                  "display": false,
                  "text": "",
                  "padding": {
                    "top": 4,
                    "bottom": 4
                  },
                  "color": "#666"
                },
                "id": "x",
                "position": "bottom"
              }
            }
          }
        },
      }
      </graph>

      This data is about ${dataType} from Intuit QuickBooks ->` + jsonData;

    const loadingMessage = {
        content: "Setting up your AI Assistant...",
        role: "assistant",
    };
    setMessages([loadingMessage]);

    const systemMessage = { content: prompt, role: "system", id: Date.now() };

    try {
        const updatedConversationHistory = [
            systemMessage,
            // {
            //     content: "Hello, I am your AI Assistant! Ask me anything about your data!",
            //     role: "assistant",
            // },
        ];

        // Wait for AI response
        const gptResponse = await sendMessageToGpt(updatedConversationHistory, "");
        // console.log(updatedConversationHistory);
        setAI_Loaded(true);


        setMessages([
          ...messages.slice(0, 1),
          {
            content:
              "Hello, I am Warren, your AI Assistant! I'm here to help you understand your data.",
            role: "assistant",
          },
        ]);

        // Extract relevant data for graphs and text
        const {textResponse, graphData } = extractGraphAndTextData(gptResponse);

        if (textResponse) {
          updatedConversationHistory.push({
              content: textResponse,
              role: "assistant",
          });
        }

        // Generate chart configurations if data suggests graphing
        if (graphData) {
            const chartConfig = generateChartConfig(graphData.graphData);

            // Dispatch chart-related data to the state for rendering
            dispatch({
                type: "SET_GRAPH_DATA",
                payload: chartConfig,
            });
            setChartConfig(chartConfig)

            // setMessages([
            //     ...messages,
            //     {
            //         content: "Here's the graphical representation of your data:",
            //         role: "assistant",
            //     },
            // ]);
        }

        // Update the conversation history and messages with AI response
        setConversationHistory(updatedConversationHistory);
        // setMessages([
        //     ...messages.slice(0, -1),
        //     {
        //         content: aiResponse.textResponse, // The textual part of the response
        //         role: "assistant",
        //     },
        // ]);

    } catch (error) {
        console.error("Error handling AI response:", error);
  }
};

const extractGraphAndTextData = (gptResponse) => {
  // Regular expression to capture content between <graph> and </graph> tags
  const graphDataRegex = /<graph>([\s\S]*?)<\/graph>/;
  const graphMatch = gptResponse.match(graphDataRegex);

  let graphData = null;

  if (graphMatch) {
      try {
          // Replace JavaScript functions with placeholder values
          const sanitizedGraphData = graphMatch[1].replace(/"callback":\s*function\s*\([\s\S]*?\}\,/g, '"callback": null,');
          
          // Parse the sanitized JSON
          graphData = JSON.parse(sanitizedGraphData);
      } catch (error) {
          console.error("Error parsing graph data JSON:", error);
      }
  }

  // Remove the graph data from the response text
  const textResponse = gptResponse.replace(graphDataRegex, "").trim();

  return {
      textResponse,
      graphData,
  };
};


const generateChartConfig = (graphData) => {
  console.log("Graph Data Input:", graphData);

  // Validate input
  if (
      !graphData ||
      typeof graphData !== "object" ||
      !graphData.type ||
      !graphData.data ||
      !graphData.options
  ) {
      console.error("Invalid or incomplete graph data:", graphData);
      throw new Error("Invalid or incomplete graph data.");
  }

  // Generate the chart configuration
  return {
      type: graphData.type,
      data: graphData.data,
      options: graphData.options,
  };
};



  useEffect(() => {
    resyncIntegrations();
  }, [data_change]);

  const resyncIntegrations = () => {
    setModalOpen(false);
    setUserInput("");
    setSelectedOption(null);
    setStep(1);
    setMessages([]);
    setUploadedFileName(null);
    setConversationHistory([]);
  };

  const handleShowFiles = () => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      setStep(1.5);
      setShowFiles(true);
    } else {
      console.error("No files available to show.");
    }
  };

  const skipToStepOnePointFive = () => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      setStep(1.5);
      setShowFiles(true);
    } else {
      console.error("No files available to skip to.");
      // Optionally notify the user
    }
  };

const handleSendMessage = async () => {
  if (!source) {
      console.error("Source is not set. Cannot proceed.");
      return;
  }

  if (source === "option") {
      const userMessage = { content: userInput, role: "user", id: Date.now() };
      const loadingMessage = {
          content: "Typing...",
          role: "assistant",
          id: Date.now(),
      };

      // Add the loading message to the messages array
      setMessages([...messages, userMessage, loadingMessage]);

      try {
        setUserInput("");

        const updatedConversationHistory = [
          ...conversationHistory,
          // ...messages, // Remove the "Typing..." placeholder
          userMessage,
        ];


          // const updatedConversationHistory = [...conversationHistory];
          // console.log(updatedConversationHistory);

          // Step 3: Construct a prompt using the top results
          const gptResponse = await sendMessageToGpt(updatedConversationHistory, "");

          setMessages([
              ...messages,
              {
                  content:
                      "Hello, I am Warren, your AI Assistant! I'm here to help you understand your data.",
                  role: "assistant",
              },
          ]);

          const extractedData = extractGraphAndTextData(gptResponse);


        // console.log(updatedConversationHistory);

          if (extractedData.textResponse) {
              updatedConversationHistory.push({
                  content: extractedData.textResponse,
                  role: "assistant",
              });
          }

          if (extractedData.graphData) {
              const chartConfig = generateChartConfig(extractedData.graphData.graphData);
              dispatch({
                  type: "SET_GRAPH_DATA",
                  payload: chartConfig,
              });
              setChartConfig(chartConfig);
              updatedConversationHistory.push({ role: "assistant", content: "Graph generated. Use the chart below for visualization.", chartConfig });
          }

          setMessages(updatedConversationHistory);


          return gptResponse;
    
      } catch (error) {
          console.error("Error handling AI response:", error);
          setUserInput("");
      }
      setUserInput("");


  } else if (source === "fileSource") {
      if (userInput.trim() === "") return;

      const userMessage = { content: userInput, role: "user", id: Date.now() };
      const loadingMessage = {
          content: "Typing...",
          role: "assistant",
          id: Date.now(),
      };

      setMessages([...messages, userMessage, loadingMessage]);

      try {
          setUserInput("");

          const { localEmbeddings, localDataObjects } = await selectClicked(uploadedFiles, selectedFiles);

          const queryResults = await queryEmbeddings(userInput, localEmbeddings, localDataObjects);

          const gptResponse = await sendToGPT(userInput, queryResults);

          const { textResponse, graphData } = extractGraphAndTextData(gptResponse);

          const updatedMessages = [
              ...messages, // Remove the "Typing..." placeholder
              userMessage,
          ];

          if (textResponse) {
              updatedMessages.push({ content: textResponse, role: "assistant" });
          }

          if (graphData) {
              try {
                  const chartConfig = generateChartConfig(graphData.graphData);
                  dispatch({
                      type: "SET_GRAPH_DATA",
                      payload: chartConfig,
                  });
                  setChartConfig(chartConfig);

                  updatedMessages.push({ role: "assistant", content: "Graph generated. Use the chart below for visualization.", chartConfig });
              } catch (error) {
                  console.error("Error generating chart config:", error);
                  updatedMessages.push({
                      content: "I encountered an issue while processing the graph data.",
                      role: "assistant",
                  });
              }
          }

          setMessages(updatedMessages);
          console.log(updatedMessages);

          return gptResponse;
      } catch (error) {
          console.error("Error in pipeline:", error);
          throw error;
      }
  }
};
    

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setUserInput("");
    setSelectedOption(null);
    setStep(1);
    setMessages([]);
    setUploadedFileName(null);
    setConversationHistory([]);
  };

  const handleBackClick = () => {
    setStep(1);
    setShowFiles(false);
    setUploadedFileName(null);
    setSelectedFiles([]);
  };

  useEffect(() => {
    const inputElement = inputRef.current;

    if (inputElement) {
      const handleKeyDown = (e) => {
        if (e.key === "Enter" && userInput.trim() !== "") {
          handleSendMessage();
        }
      };

      inputElement.addEventListener("keydown", handleKeyDown);

      return () => {
        inputElement.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [userInput]);

  useEffect(() => {
    // Scroll to the bottom of the chat content when new messages are added
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }, [messages]);

  const openPanel = () => {
    // Add logic to open the panel
    setPanelOpen(true);
    setStep(2);
  };

  const closePanel = () => {
    // Add logic to close the panel
    if (onClose){
      onClose()
    }
    // setSelectedFiles([]);
    // setUploadedFileName(null);
    setPanelOpen(false);
    setStep(1)
  };

  const setStepBackToStepOnePointFive = () => {
    setStep(1.5);
    setShowFiles(true);
    setPanelOpen(true);
  }

  const setStepBackToStepOne = () => {
    setPanelOpen(true);
    setStep(1);
  }

  useEffect(() => {
    if (ai_loaded && inputRef.current) {
      const input = inputRef.current;
      input.focus();
    }
  }, [ai_loaded]);

  function parseCSVAsync(rawCSV, config = {}) {
    return new Promise((resolve, reject) => {
      Papa.parse(rawCSV, {
        ...config,
        complete: (results) => {
          // Check if rows are objects (header: true) or arrays (header: false)
          const isHeader = config.header || false;
          let filteredData;

          if (isHeader) {
            // For object rows, filter out rows where all values are empty
            filteredData = results.data.filter((row) =>
              Object.values(row).some((cell) => cell !== "")
            );
          } else {
            // For array rows, filter out rows where all cells are empty
            filteredData = results.data.filter((row) =>
              row.some((cell) => cell !== "")
            );
          }

          resolve({ ...results, data: filteredData });
        },
        error: (error) => reject(error),
      });
    });
  }

  const fetchEmbeddingData = async () => {
    try {
      // Retrieve stored embeddings and associated data (e.g., from your previous logic)
      const embeddings = await retrieveStoredEmbeddings(); // Implement this function
      const data = await retrieveStoredData(); // Implement this function

      setStoredEmbeddings(embeddings);
      setStoredData(data);
    } catch (error) {
      console.error("Error fetching embedding data:", error);
    }
  };

  const handleQuery = async (userInput) => {
    try {
        if (!embeddings || !dataObjects) {
            console.error("Embeddings or data objects are not loaded.");
            return;
        }

        const results = await queryEmbeddings(userInput, embeddings, dataObjects);
        setQueryResults(results);
    } catch (error) {
        console.error("Error handling query:", error);
    }
};
  const MAX_TOKENS_PER_CHUNK = 8000; // Slightly below the max context length to leave room for overhead

  function splitIntoChunks(text, maxTokens) {
    const chunks = [];
    let currentChunk = "";

    const lines = text.split("\n");
    for (const line of lines) {
      const estimatedTokens = line.length / 4; // Approximate 4 characters per token
      if (currentChunk.length + estimatedTokens > maxTokens) {
        chunks.push(currentChunk);
        currentChunk = ""; // Reset current chunk
      }
      currentChunk += line + "\n";
    }

    if (currentChunk.length > 0) {
      chunks.push(currentChunk);
    }

    return chunks;
  }

const selectClicked = async () => {
  setLoading(true);
  setStep(2);

  const openai = new OpenAI({
    apiKey: "sk-proj-GPCLdXllynODtIlUJDDiT3BlbkFJv5grBkM31saweFM0GDka",
    dangerouslyAllowBrowser: true,
  });

  if (uploadedFiles && selectedFiles.length > 0) {
      const filesToProcess = uploadedFiles.filter((file) =>
          selectedFiles.includes(file._id)
      );

      let combinedData = [];
      const localEmbeddings = [];
      const localDataObjects = [];

      try {
          // Process each selected file
          for (const file of filesToProcess) {
              if (!file || !file.fileName || !file.content) {
                  console.error("Incomplete file data:", file);
                  continue; // Skip incomplete files
              }

              if (file.fileName.toLowerCase().endsWith(".csv")) {
                  const rawCSV = file.content;

                  if (typeof rawCSV !== "string") {
                      throw new TypeError("CSV content is not a string.");
                  }

                  const parsedCSV = await parseCSVAsync(rawCSV, {
                      header: true,
                      skipEmptyLines: true,
                  });

                  combinedData.push(...parsedCSV.data);
              } else {
                  alert("Unsupported file type. Please upload a .csv file.");
                  setLoading(false);
                  return;
              }
          }

          if (combinedData.length === 0) {
              alert("No valid data was found in the selected files.");
              setLoading(false);
              return;
          }

          // Convert combinedData to CSV format as a string
          const csvContent = Papa.unparse(combinedData);

          console.log("CSV content generated successfully.");

          // Split CSV content into manageable chunks
          const csvChunks = splitIntoChunks(csvContent, MAX_TOKENS_PER_CHUNK);

          console.log(`Split CSV content into ${csvChunks.length} chunks.`);

          // Process each chunk and create embeddings and data objects
          for (const chunk of csvChunks) {
              const embedding = await openai.embeddings.create({
                  model: "text-embedding-3-small",
                  input: chunk,
                  encoding_format: "float",
              });

              console.log("Embedding created for a chunk:", embedding);

              // Store embedding
              localEmbeddings.push(embedding);

              // Create corresponding data object
              const dataObject = {
                  title: `Chunk ${embeddings.length}`,
                  content: chunk,
                  embedding,
              };

              localDataObjects.push(dataObject);
              setAssistantData(dataObject);
              await connectQuickbooks(dataObject);

          }

          // Set file source
          const uploadSource = { localEmbeddings, localDataObjects };
          setSource("fileSource"); // Use a consistent identifier
          setFileSource(uploadSource);

          console.log("Embeddings and data objects created successfully.");

          setStep(2); // Proceed to the next step
          return uploadSource; // Return the processed data for further use
      } catch (error) {
          console.error("Error processing files:", error);
          alert("There was an error processing the files. Please try again.");
          return null;
      } finally {
          setLoading(false);
      }
      // return { localEmbeddings, localDataObjects };
  } else {
      console.error("No files selected.");
      alert("Please select at least one file to proceed.");
      setLoading(false);
      return null;
  }
};

  
  const handleClickedFile = (id, isDelete = false) => {
    if (isDelete) {
      // Remove the file from selectedFiles if it's being deleted
       
      setSelectedFiles((prevSelectedFiles) =>
        prevSelectedFiles.filter((fileId) => fileId !== id)
      );
      dispatch({
        type: "SET_UPLOADED_FILES",
        payload: uploadedFiles.filter((file) => file._id !== id),
      });
    } else {
       
      if (selectedFiles.includes(id)) {
        setSelectedFiles(selectedFiles.filter((fileId) => fileId !== id));
      } else {
        setSelectedFiles([...selectedFiles, id]);
      }
    }
  };

const handleAddToBoard = () => {
  const lastMessage = messages[messages.length - 1]; // Get the last message

  if (!lastMessage) {
      console.error("No messages available.");
      return;
  }

  const isChartMessage = !!lastMessage.chartConfig;
  const isValidRegularMessage = lastMessage.role === "assistant" && lastMessage.content?.trim() !== "";

  if (isChartMessage && isValidRegularMessage) {
      // Add the regular message first, then delay the chart message slightly
      addToBoard(lastMessage);
      setTimeout(() => addToBoardGraph(lastMessage), 600);
  } else if (isChartMessage) {
      // Only chart message exists
      addToBoardGraph(lastMessage);
  } else if (isValidRegularMessage) {
      // Only regular assistant message exists
      addToBoard(lastMessage);
  }
};

  
  return (
    <>
      <Box 
        sx={{ 
          display: "flex", 
          alignItems: "center", 
          position: "fixed",
          left: "235px", // Position the panel to the right

        }}
        
      >
        <Box
          className="second-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "fixed",
            left: "235px", // Position the panel to the right
            top: "51px",
            bottom: "0px",
            // zIndex: 9999,
            width: panelOpen ? "100%" : 0, // Adjust the width as needed
            maxWidth: "400px",
            height: "auto",
            maxHeight: "100vh",
            overflow: "hidden",
            overflowY: "auto",
            backgroundColor: "#ffffff", // Add your desired background color
            // boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.2)", // Add a box shadow if needed
            // transition: "transform 0.3s ease-in-out", // Add transition for smooth open/close
            // transform: panelOpen ? "translateX(0)" : "translateX(100%)", // Slide in from the right
            borderRight: "1px solid #e0e0e0",
          }}
          role="complementary"
        >
          <Box 
          sx={{ 
            display: "grid",
            gridTemplateColumns: "1fr",
            width: "100%",
            height: "65px",
          }}
          >
            {/* Toggle Button */}
            <Button
              onClick={() => setPanelOpen((prev) => !prev)}
              sx={{
                bgcolor: "#4a4a4a",
                ml: panelOpen ? 0 : 0, // Adjust position when closed
                transition: "margin-left 0.3s ease-in-out",
                position: "fixed",
                left: panelOpen ? "630px" : "235px", // Adjust position when closed
                bottom: "50px",
                color: "#FFFFFF",
                pl: "0px",
                pr: "0px",
                zIndex: 9999,
              }}
            >
              {panelOpen ? <ChevronLeft /> : 
                <div
                  style={{ 
                    fontWeight: 500,
                    ml: 0, 
                    pl: 0,
                    transform: "rotate(270deg)", 
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "20px",
                    height: "120px",
                    }}
                >
                  <InsightsOutlinedIcon
                    size="large"
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  <Typography variant="h6" style={{ fontWeight: 500, color: "#ffffff" }}>
                    Warren
                  </Typography>
                </div>
            
              }
            </Button>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                width: "100%",
                height: "100%",
                color: "#00396d",
              }}
            
            >
              <DialogTitle 
                style={{ 
                  textAlign: "left", 
                  padding: "23px 20px 20px 20px",
                  color: "#4a4a4a",
                  background: "linear-gradient(90deg, rgba(255,107,107,0.4), rgba(77,138,255,0.7))",
                  width: "100%",
                  height: "100%",
                  // borderBottom: "1px solid #e0e0e0",
                }}
              >
                {/* Warren Title */}
                <Typography variant="h5" component="div" style={{ fontWeight: 600 }}>
                <InsightsOutlinedIcon
                    sx={{
                      fontSize: "30px",
                    }}
                  />
                  {"  "} Warren
                </Typography>
              </DialogTitle>
            </Box>            
          </Box>
          {step === 1 && (
            <>
              <div
                className="row mb-3"
                style={{
                  display: "grid",
                  gridTemplateColumns: "7fr 0.5fr",
                  width: "100%",
                  maxHeight: "50px",
                }}
              >
                <Box>
                  <DialogTitle style={{ textAlign: "left" }}>
                    {/* Select or Upload a data set */}
                    <Typography variant="h5" component="div" style={{ fontWeight: 500, }}
                      sx={{
                        mt: 1,
                      }}                    
                    >
                      Select Data Source
                    </Typography>
                  </DialogTitle>
                </Box>
              </div>
              <DialogContent
                className="chat-container"
                style={{ height: "85%" }}
              >
                <Typography variant="h6" component="div" style={{ fontWeight: 500, }}
                >
                  QuickBooks Data
                </Typography>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "justify",
                  border: "1px solid #e0e0e0",
                  borderRadius: "5px",


                }}>
                  {allBoards &&
                        allBoards.boards &&
                        allBoards.boards.length !== 0 && (                   
                        <Typography
                          gutterBottom
                          sx={{
                            color: "text.primary",
                            fontSize: 18,
                            color: "black"
                          }}>
                          <IntegrationsButtonAddBoard />
                        </Typography>
                      )}
                </Box>
                <Box className="ui buttons w-100">

                  <Button
                    variant="outlined"
                    className={`ui m-2 ${
                      selectedOption === "P&L" ? "active" : ""
                    }`}
                    onClick={() => handleOptionSelect("P&L")}
                  >
                    Use Data from Quickbooks
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10.854 8.354a.5.5 0 0 0 0-.708l-5-5a.5.5 0 1 0-.708.708L9.293 8l-4.147 4.146a.5.5 0 0 0 .708.708l5-5z"/>
                    </svg>                        

                  </Button>
                  {/* <button
                    className={`ui button secondary m-2 ${selectedOption === 'Balance' ? 'active' : ''}`}
                    onClick={() => handleOptionSelect('Balance')}
                  >
                    Balance
                  </button>
                  <button
                    className={`ui button secondary m-2 ${selectedOption === 'Cash Flow' ? 'active' : ''}`}
                    onClick={() => handleOptionSelect('Cash Flow')}
                  >
                    Cash Flow
                  </button> */}
                </Box>

                <Typography variant="h6" component="div" style={{ fontWeight: 500, }}
                  sx={{
                  mt: 2,
                  }}
                >
                  Uploaded Files
                </Typography>
                <Box className="w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "justify",
                    border: "1px solid #e0e0e0",
                    borderRadius: "5px",
                    height: "200px",
                    overflowY: "auto",
                  }}
                >
                  {uploadedFiles && uploadedFiles.length > 0 ? (
                    uploadedFiles.map((file) => {
                      return (
                        <List dense={true}>
                          <File
                            key={file._id}
                            id={file._id}
                            file={file}
                            handleClickedFile={handleClickedFile}
                            selectedFiles={selectedFiles}
                          />
                        </List>
                      );
                    })                  
                  ) : (
                    <Box sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}>
                      <Typography>No files uploaded yet.</Typography>
                    </Box>  
                  )}
                </Box>
                <Box className="ui buttons w-100">
                  <Button
                    className={`ui m-2`}
                    variant="outlined"
                    onClick={() => {
                      if (selectedFiles == 0) {
                        alert("Please select a file to proceed.");
                      } else if (selectedFiles !== null) {
                        selectClicked();
                        setChartConfig(null);
                      }
                    }}
                  >
                    Use Selected file
                    {selectedFiles.length !== 1 ? "s" : ""} {"  "}
                    {/* <Chip label={selectedFiles.length} /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10.854 8.354a.5.5 0 0 0 0-.708l-5-5a.5.5 0 1 0-.708.708L9.293 8l-4.147 4.146a.5.5 0 0 0 .708.708l5-5z"/>
                    </svg>
                  </Button>
                  <CartBadge badgeContent={selectedFiles.length} color="info" overlap="circular" />


                </Box>

                {/* Drag and Drop Upload Container */}
                <Typography variant="h6" component="div" style={{ fontWeight: 500, }} 
                sx={{
                  mt: 2,
                }}>
                  Upload Files
                </Typography>
                <Box
                  className="ui segment upload-container"
                  style={{
                    height: "200px",
                    border: "2px dashed #e0e0e0", // Black dashed line border
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    const files = e.dataTransfer.files;
                    handleFileUpload({ target: { files: files } });
                  }}
                >
                  <p>Drag and drop a .csv files here or click to upload.</p>
                   <Button
                    component="label"
                    role={"button"}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}

                  >
                    Upload files
                    <VisuallyHiddenInput
                      type="file"
                      id="fileInput"
                      onChange={handleFileUpload}
                      accept=".csv"
                      multiple
                    />
                  </Button>
                  {uploadedFileName ? (
                    <Typography sx={{ m: 1}}>Uploaded Files: {uploadedFileName}</Typography>
                  ) : (
                    ""
                  )}
                  {uploadedFileName && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleShowFiles}
                      disabled={uploadingUserFiles}
                      sx={{
                        mt: 2,
                        m: 1,
                        cursor: uploadingUserFiles ? "not-allowed" : "pointer",
                        opacity: uploadingUserFiles ? 0.6 : 1, // Optional for better UX
                      }}
                    >
                      {uploadingUserFiles ? "Uploading..." : "Next"}
                    </Button>

                  )}
                </Box>
              </DialogContent>
            </>
          )}
          {step === 1.5 && showFiles && (
            <>
              <div
                style={{
                  height: "90%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  className="row mb-3"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "7fr 1fr",
                    width: "100%",
                    maxHeight: "50px",
                  }}
                >
                  <div>
                    <DialogTitle
                      style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                      <Typography variant="h5" component="div" style={{ fontWeight: 500, }}
                        sx={{
                          mt: 1,
                        }}                    
                      >
                        Select file(s)
                      </Typography>                      
                    </DialogTitle>
                  </div>
                </div>
                <DialogContent style={{ height: "89%" }}>
                  <div>
                    <Grid item xs={12} md={12}>
                      <div>
                        <List dense={true}>
                          {uploadedFiles &&
                            uploadedFiles.map((file) => {
                              return (
                                <File
                                  key={file._id}
                                  id={file._id}
                                  file={file}
                                  handleClickedFile={handleClickedFile}
                                  selectedFiles={selectedFiles}
                                />
                              );
                            })}
                        </List>
                      </div>
                    </Grid>
                  </div>
                </DialogContent>
                <div
                  className="ui buttons w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      display: "block",
                      width: "90%",
                      borderRadius: "5px",
                      mb: 2,
                    }}
                    onClick={() => {
                      if (selectedFiles == 0) {
                        alert("Please select a file to proceed.");
                      } else if (selectedFiles !== null) {
                        selectClicked();
                        setChartConfig(null);
                      }
                    }}
                  >
                    Select ({selectedFiles.length} file
                    {selectedFiles.length !== 1 ? "s" : ""})
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleBackClick}
                    sx={{ width: "90%", borderRadius: "5px" }}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              {loading ? (
                <>
                  <DialogContent
                    className="chat-container pb-2"
                    style={{
                      height: "85%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={32} color="inherit" />
                  </DialogContent>
                </>
              ) : (
                <>
                  <DialogContent
                    className="chat pb-2"
                    // style={{ height: "85%" }}
                    ref={chatContainerRef} style={{ overflowY: "auto", height: "85%", marginTop: "10px" }}
                  >
                    <div
                      className="chat-content"
                      style={{ width: "100%", marginBottom: "120px" }}
                      ref={(element) => setScrollContainer(element)} // Set the ref to the chat content container
                    >
                      <div className="chat-container">
                        {messages.map((message, index) => (
                          <div
                            key={index}
                            className={`message ${message.role === "user" ? "user-message" : "ai-message"}`}
                          >
                            <div 
                              className={`message-bubble ${message.role === "user" ? "user-bubble" : "ai-bubble"}`}
                            >
                              {message.chartConfig ? (
                                <ChartComponent config={message.chartConfig} chartId={`chart-${index}`} />
                              ) : (
                                <div dangerouslySetInnerHTML={{ __html: message.content || "" }} />
                              )}
                            </div>
                          </div>
                        ))}

                      </div>
                      {messages.length >= 2 && (
                        <div
                          // key={index}
                          className="ui menu pb-3"
                          style={{
                            position: "absolute",
                            bottom: "130px", // Adjust the distance from the bottom
                            left: "50%", // Center horizontally
                            transform: "translateX(-50%)", // Center using transform
                            display: "flex", // Enable flex behavior
                            justifyContent: "center", // Center horizontally within the container
                            alignItems: "center", // Center vertically within the container
                            width: "100%", // Take up the full width of the container
                          }}
                        >
                          {/* <button
                            className="ui button"
                            style={{ width: "40%" }}
                            onClick={copyToClipboard}
                          >
                            Copy to Clipboard
                          </button> */}
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={setStepBackToStepOnePointFive}
                            sx={{ marginRight: "10px", }}
                          >
                            Back
                          </Button>

                          <Button
                            variant="contained"
                            color="secondary"
                            // className="ui button"
                            style={{ width: "50%" }}
                            onClick={handleAddToBoard}
                          >
                            Add to Project
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M10.854 8.354a.5.5 0 0 0 0-.708l-5-5a.5.5 0 1 0-.708.708L9.293 8l-4.147 4.146a.5.5 0 0 0 .708.708l5-5z"/>
                            </svg>                        
                          </Button>
                        </div>
                      )}
                    </div>
                  </DialogContent>
                  <div className="chat-input" style={{ display: "flex" }}>
                    {/* <TextField
                      type="text"
                      placeholder="Ask AI a question about your financials..."
                      disabled={ai_loaded ? false : true}
                      value={userInput}
                      onChange={handleInputChange}
                      ref={inputRef} // Add the ref to the input field
                      style={{
                        flex: "1", // Allow the input to grow and take up available space
                        padding: "10px", // Adjust padding as needed
                        border: "none",
                        borderBottom: "1px solid #ddd",
                        borderRadius: "0",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        height: "100px",
                      }}
                      
                    /> */}
                    <TextField
                      type="text"
                      placeholder="Ask AI a question about your financials..."
                      disabled={!ai_loaded}
                      value={userInput}
                      onChange={handleInputChange}
                      ref={inputRef}
                      multiline
                      minRows={3} // Adjust based on preference
                      maxRows={3} // Optional: Set max rows to prevent excessive expansion
                      style={{
                        flex: "1",
                        padding: "10px",
                        border: "none",
                        borderBottom: "1px solid #ddd",
                        borderRadius: "0",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        height: "120px", // You can remove this if using minRows
                        overflow: "auto", // Ensure scrollbar appears when needed
                        wordWrap: "break-word", // Ensure text wraps properly
                      }}
                    />

                    <button
                      className="ui button"
                      onClick={handleSendMessage}
                      // onClick={() => handleQueryPipeline(uploadedFiles, selectedFiles, userInput)}
                      style={{
                        padding: "0.5em", // Adjust the padding as needed
                        background: "none",
                        border: "none",
                        color: "#000000", // Match the chat box color
                        width: "40px",
                      }}
                    >
                      < SendIcon />
                      {/* <i className="paper plane outline icon"></i>{" "} */}
                      {/* Semantic UI send arrow icon */}
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default DtGpt;
