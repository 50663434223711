/* eslint-disable */
import { addCompanyName, myCompany } from "../../src/context/company-service";
import { styled } from "@mui/material/styles";
// Removed unused import: makeStyles
import { Box, Card, Button, Typography, Menu, MenuItem, Avatar, IconButton } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LOGO from "../assets/Dx_logo.png";
import Profile from "../assets/profile.png";
import { AuthContext } from "../context/context";
import { getTeams } from "../context/team-service";
import CompanyCard from "./Stepper/CompanyCard";
import { getCompany } from "../context/fetch-service";
import AppTour from "./AppTour";
import Feedback from "./Feedback";

const PREFIX = "MenuBar";

// Define drawerWidth before using it in classes
const drawerWidth = 240;

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  TopNav: `${PREFIX}-TopNav`,
  Logo: `${PREFIX}-Logo`,
  MENU: `${PREFIX}-MENU`,
  ProfileIcon: `${PREFIX}-ProfileIcon`,
  lineVertical: `${PREFIX}-lineVertical`,
  btn: `${PREFIX}-btn`,
  company: `${PREFIX}-company`,
  companyArrow: `${PREFIX}-companyArrow`,
};

// Styled component using the classes and drawerWidth
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
  },

  [`& .${classes.appBar}`]: {
    left: "0px !important",
    height: "50px",
    marginLeft: "0px",
    background: "#fff",
    minWidth: "720px",
  },

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    color: "black",
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
    overflow: "hidden",
    backgroundColor: "white",
    color: "black",
  },

  // necessary for content to be below app bar
  [`& .${classes.content}`]: {
    flexGrow: 1,
    paddingTop: "50px",
    overflow: "hidden",
    minWidth: "1000px",
  },

  [`& .${classes.TopNav}`]: {
    width: "100%",
    marginLeft: "0rem",
    marginRight: "0rem",
    height: "20px",
  },

  [`& .${classes.Logo}`]: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },

  [`& .${classes.MENU}`]: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    color: "black",
  },

  [`& .${classes.ProfileIcon}`]: {
    cursor: "pointer",
    "& img": {
      width: "2.5rem",
      height: "2.5rem",
      borderRadius: "50%",
      marginRight: "1.5rem",
      marginTop: "15px",
    },
  },

  [`& .${classes.lineVertical}`]: {
    opacity: "0.1",
    borderLeft: "1px solid black",
    height: "2.5rem",
    marginLeft: "5px",
    marginRight: "15px",
    marginTop: "10px",
  },

  [`& .${classes.btn}`]: {
    color: "black",
    margin: "0 .7rem 0 0",

    "& svg": {
      fontSize: "2rem",
    },
  },

  [`& .${classes.company}`]: {
    width: "131px",
    marginTop: "15.49px",
    fontWeight: "800",
    whiteSpace: "wrap",
  },

  [`& .${classes.companyArrow}`]: {
    width: "21px",
    marginTop: "15.49px",
    fontWeight: "800",
    whiteSpace: "wrap",
  },
}));

function MenuBar(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    state: { user, purchasing, teams, companyName },
    dispatch,
  } = useContext(AuthContext);

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
    });
    localStorage.clear();
    navigate("/login");
  };

  const handleProfileClick = (event) => setProfileAnchorEl(event.currentTarget);
  const handleProfileClose = () => setProfileAnchorEl(null);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      className={classes.ProfileIcon}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  ));

  const [accountActive, setAccountActive] = useState(false);

  // Fetch teams data
  const fetchData = async () => {
    try {
      const teamMembersResponse = await getTeams();
      dispatch({
        type: "SET_TEAMS",
        payload: teamMembersResponse.data.data[0],
      });
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  // Refactored useEffect for purchasing
  useEffect(() => {
    const checkSubscriptionAndFetchData = async () => {
      if (purchasing[0] && purchasing[0].subscriptionDetails) {
        const endDateTime =
          purchasing[0].subscriptionDetails.current_period_end;
        const endDate = new Date(endDateTime * 1000);

        const currentDate = new Date();
        if (endDate > currentDate) {
          setAccountActive(true);
        }
      }
      await fetchData();
    };

    checkSubscriptionAndFetchData();
  }, [purchasing]); // Dependencies remain the same

  // Refactored useEffect for teams
  useEffect(() => {
    const checkTeams = () => {
      if (teams.myTeams && teams.myTeams.length > 0) {
        setAccountActive(true);
      }
    };

    checkTeams();
  }, [teams]); // Dependencies remain the same

  return (
    <Root>
      <div>
        <div
          className="ui menu"
          style={{
            borderBottom: "0 none !important",
            boxShadow: "none",
            position: "fixed",
            zIndex: "1000",
            width: "100%",
            backgroundColor: "#ffffff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 3,
            }}
          >
            <div className={[classes.toolbar, classes.Logo].join(" ")}>
              <Link to="/projects">
                <img
                  src={LOGO}
                  alt="Datatrixs-Logo"
                  style={{
                    width: "40.20px",
                    height: "23px",
                    marginTop: "15.49px",
                  }}
                />
              </Link>
            </div>
            {companyName && (
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="dropdown-custom-components"
                >
                  <div
                    className="ui inline dropdown"
                    style={{ fontWeight: "revert", display: "flex" }}
                  >
                    <div className={classes.company}>{companyName}</div>
                    <div className={classes.companyArrow}>
                      <ExpandMoreIcon />
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ marginTop: "5px" }}>
                  <Dropdown.Item as={Link} to="/company">
                    Update company name
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            <button
              className="ui button tiny mr-3 m-3"
              style={{
                backgroundColor:
                  location.pathname === "/projects"
                    ? "#FFFFFF"
                    : "white",
                borderBottom:
                  location.pathname === "/projects"
                    ? "solid 1px red"
                    : "white",
                borderRadius:
                  location.pathname === "/projects" ? "0" : "0",
              }}
            >
              <Link to="/projects" style={{ color: "black" }}>
                Projects
              </Link>
            </button>
            <button
              className="ui button tiny mr-3 m-3"
              style={{
                backgroundColor:
                  location.pathname === "/reports" ? "#FFFFFF" : "white",
                borderBottom:
                  location.pathname === "/reports" ? "solid 1px red" : "white",
                borderRadius: location.pathname === "/reports" ? "0" : "0",
              }}
            >
              <Link to="/reports" style={{ color: "black" }}>
                Reports
              </Link>
            </button>
          </Box>
          <Box
            className="fifth-step"
            sx={{
              display: "flex",
            }}
          >
            <AppTour />
          </Box>
          <Box
            style={{ float: "right" }}
            sx={{
              display: "flex",
            }}
          >
            <IconButton onClick={handleProfileClick} sx={{ mr: 2 }}>
              <Avatar src={user?.profile || Profile} alt="User Profile" />
            </IconButton>

            <Menu anchorEl={profileAnchorEl} open={Boolean(profileAnchorEl)} onClose={handleProfileClose}>
              <MenuItem disabled>
                {user && user.firstName && user.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : user.email }

              </MenuItem>
              <MenuItem component={Link} to="/company">Company</MenuItem>
              <MenuItem component={Link} to="/settings">Settings</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </div>

        <main className={classes.content}>{props.children}</main>
      </div>
    </Root>
  );
}

export default MenuBar;
