/* eslint-disable */
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
import { Box, Dialog, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import plusIcon from "../assets/plus_icon.png";
import SubscriptionPopup from '../views/Settings/SubscriptionPopup';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const PREFIX = 'OverlaySubscription';

const classes = {
  paper: `${PREFIX}-paper`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.paper}`]: { minWidth: '600px' }
}));

const OverlaySubscription = ({ renderButton }) => {

  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [epochTime, setEpochTime] = useState(1692135141);
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    textTransform: 'none',
    backgroundColor: 'black', // Change to desired hover background color
    textAlign: 'left',
    color: 'white', // Change to desired hover text color
    width: '100%',
    borderRadius: isHovered ? '5px' : '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-center',
    fontWeight: 700,
    transition: 'border-radius 0.3s ease',
    height: '40px',
    border: 'solid 1px',
  };

  const handleCloseOverlay = () => {
    setOpen(false);
  };



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const DefaultButton = () =>{
    return(
      <p variant="text"
        style={{
          paddingBottom: '0px',
        }}
        onClick={handleClickOpen}>

        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '0px',
          }}
        >
          {/* <img src={plusIcon} style={{ marginRight: '10px' }} /> */}
          <Button 
            className='ui button icon first-step'
            style={buttonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Add More Projects
            <WorkspacePremiumIcon fontSize="medium" style={{ color: "gold"}} />
          </Button>
        </Box>
      </p>
    )
  }

  const ButtonToRender = renderButton ? renderButton(handleClickOpen) : <DefaultButton />;

  return (
    (<Root>
      {ButtonToRender}
      {disabled
        ? (
          <Dialog maxWidth="md" open>
            <DialogContent sx={{ padding: '20px' }}>
            <SubscriptionPopup />
            </DialogContent>
          </Dialog>
        )
        : (
          <Dialog maxWidth="md" onClose={handleClose} open={open}>
            <DialogContent sx={{ padding: '20px' }}>
            <SubscriptionPopup />
            </DialogContent>
          </Dialog>

        )}
    </Root>)
  );
};

export default OverlaySubscription;
