import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Dialog, DialogContent, CircularProgress } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from 'tss-react/mui';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import React, { useState, useEffect } from 'react';
import { AuthContext } from '../../context/context';
import { createAndAddBoardName } from '../../context/intuit-service';
import { countMatchingOwners } from './helpers';
import Overlay from '../Overlay';

const PREFIX = 'DuplicateClient';

const classes = {
  paper: `${PREFIX}-paper`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.paper}`]: { minWidth: '600px' }
}));

const DuplicateClient = (props) => {

  const {
    state: {
      user, switchUser, isAuthenticated, access_token, purchasing, projects, allBoards, clientsPurchased
    },
    dispatch,
  } = React.useContext(AuthContext);

  const [name, setName] = useState(props.board ? "Copy of " + props.board.boardName : 'Project Name');
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  useEffect(() => {
    if (isDuplicating) {
      duplicateClient();
      setIsDuplicating(false); // Reset duplication flag after the process
    }
  }, [isDuplicating]);

  useEffect(() => {
     
    console.log(clientsPurchased + 3 - countMatchingOwners(allBoards));
  }, [clientsPurchased])
  
  const handleCloseBackDrop = () => {
    setBackDropOpen(false);
  };
  
  const handleToggleBackDrop = () => {
    setBackDropOpen(!backDropOpen);
  };

  const duplicateClient = async () => {
    setIsLoading(true); // Start loading indicator
    try {
      const boardsArray = allBoards.boards;
       
      const originalClientIndex = boardsArray.findIndex(client => client.boardName === props.board.boardName);

      if (originalClientIndex === -1) {
        throw new Error('Original client not found');
      }

      const originalClient = boardsArray[originalClientIndex];
       

      const data = {
        sharedId: switchUser.userName,
        boardName: name,
        boardId: props.boardId,
        duplicateClient: true,
        layout: originalClient.layout,
        tabs: originalClient.tabs,
        access: originalClient.access
      };
      const newClientResponse = await createAndAddBoardName(data);

      if (newClientResponse.status === 200) {
         
        dispatch({
          type: 'ADD_NEW_CLIENT',
          payload: newClientResponse.data
        });
        props.getBoards(true);
         
      } else {
         
      }
    } catch (error) {
      console.error('Error duplicating client:', error);
    }
    handleCloseBackDrop();
    setIsLoading(false); // Stop loading indicator
  };

  const newBoard = (
    (<Root>
      <Typography
        style={{ paddingBottom: "0px" }}
        gutterBottom
        sx={{
          color: "text.primary",
          fontSize: 15
        }}>
        {clientsPurchased + 3 - countMatchingOwners(allBoards) > 0 ? (
            <MenuItem onClick={handleToggleBackDrop}>
              <ListItemIcon>
                <ContentCopyOutlinedIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Duplicate" />
            </MenuItem>
          ) : (
            <>
              {countMatchingOwners(allBoards) < 3 ? (
                <MenuItem onClick={handleToggleBackDrop}>
                  <ListItemIcon>
                    <ContentCopyOutlinedIcon fontSize="medium" />
                  </ListItemIcon>
                  <ListItemText primary="Duplicate" />
                </MenuItem>
              ) : (
                <Overlay renderButton={(handleClickOpen) => (
                  <MenuItem onClick={handleClickOpen}>
                    <ListItemIcon>
                      <ContentCopyOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText primary="Duplicate" />
                  </MenuItem>
                )} />
              )}
            </>
          )}
      </Typography>
    </Root>)
  );

  return (
    <>
      {newBoard}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }} open={backDropOpen} onClose={handleCloseBackDrop}>
        <Dialog classes={{ paper: classes.paper }} onClose={handleCloseBackDrop} open={backDropOpen}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent style={{ padding: '50px 50px 50px 50px' }}>
              <h1 style={{ paddingBottom: '15px', width: '100%' }}>Project Name</h1>
              <TextField
                style={{ paddingBottom: '15px', width: '100%' }}
                onChange={(event) => setName(event.target.value)}
                required
                label="Required"
                value={name}
                variant="standard"
              />
              <Button 
                style={{ paddingTop: '15px', width: '100%', backgroundColor: 'Black' }} 
                onClick={() => setIsDuplicating(true)} 
                variant="contained" 
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : (props.edit ? "Save" : "Duplicate")}
              </Button>
            </CardContent>
          </Card>
        </Dialog>
      </Backdrop>
    </>
  );
};

export default DuplicateClient;
