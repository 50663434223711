/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import Tour from 'reactour';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import addCollaborator from '../assets/undraw_collaborators_re_hont.svg';
import aiAssistantTour from '../assets/undraw_artificial_intelligence_re_enpp.svg';
import contentTour from '../assets/undraw_add_content_re_vgqa.svg';
import shareTour from '../assets/undraw_share_link_re_54rx.svg';
import endTour from '../assets/undraw_approve_qwp7.svg';
import './AppTour.css';


// Define custom styles
const tourStyles = {
  tooltip: (base) => ({
    ...base,
    backgroundColor: '#222', // Change the background color of the tooltip
    color: '#hjuyse', // Change the text color
    borderRadius: '10px', // Add rounded corners
    padding: '20px', // Increase padding
    maxWidth: '400px', // Set maximum width for the tooltip
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Add box shadow
  }),
  button: (base) => ({
    ...base,
    backgroundColor: '#4CAF50', // Customize the background color of buttons
    color: '#fff', // Change the text color of buttons
    borderRadius: '5px', // Add rounded corners to buttons
    padding: '10px 20px', // Increase padding of buttons
    border: 'none', // Remove border from buttons
    cursor: 'pointer', // Change cursor to pointer on hover
  }),
  close: (base) => ({
    ...base,
    color: '#ff0000', // Customize the color of the close button
  }),
};

const AppTour = () => {

  const [isTourOpen, setIsTourOpen] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (!isTourOpen) {
      setCurrentStep(0);
    }
  }, [isTourOpen])

  const handleHover = (toggle) => {
    setHoveredButton(toggle);
  };

  const steps = [
    {
      selector: '.first-step',
      content: (
        <div>
          <img className="mb-3" src={addCollaborator} style={{ width: '100%', border: '1px solid rgb(222, 222, 223)' }} /> 
          <p><strong>Get Started</strong> – Begin by adding a new project to unlock insights tailored to your business.</p>
        </div>
      ),
    },
    {
      selector: '.second-step',
      content: (
        <div style={{ fontWeight: '500' }}>
          <img className="mb-3" src={aiAssistantTour} style={{ width: '100%', border: '1px solid rgb(222, 222, 223)' }} />
          <p>
            <strong>Meet Warren</strong> –Your AI-powered control center for business intelligence. Warren delivers insights through charts and written analysis, helping you track trends, assess performance, and receive personalized recommendations.
          </p>
        </div>
      ),
    },
    {
      selector: '.third-step',
      content: (
        <div>
          <img className="mb-3" src={contentTour} style={{ width: '100%', border: '1px solid rgb(222, 222, 223)' }} />
          <p> 
            <strong>Advanced Financial Analysis</strong> – Finance professionals can dive deeper by analyzing financial statements, applying formulas, and visualizing data with charts and comments.
          </p>
        </div>
      ),
    },
    {
      selector: '.fourth-step',
      content: (
        <div>
          <img className="mb-3" src={shareTour} style={{ width: '100%', border: '1px solid rgb(222, 222, 223)' }} />
          <p>
          <strong>Seamless Collaboration</strong> – Share your projects with teammates and stakeholders. Use Datatrixs to streamline discussions, generate real-time insights, and save answers for future reference.
          </p>
        </div>
      ),
    },
    {
      selector: '.fifth-step',
      content: (
        <div>
          <img className="mb-3" src={endTour} style={{ width: '100%', border: '1px solid rgb(222, 222, 223)' }} />
          <p>
          <strong>Tour Complete!</strong> – You’re all set! Click here anytime to revisit the tour and explore more features.
          </p>
        </div>
      ),
    },


  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "20px",
        }}
      >
        <button
          onClick={() => setIsTourOpen(true)}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="lightbulb-button"
        >
          <HelpOutlineIcon
            style={{
              color: hoveredButton ? "#000000" : "#000000",
              transition: "0.2s ease-in-out",
              width: "25px",
              height: "25px",
            }}
          />
        </button>

        {/* <button
          onClick={() => setIsTourOpen(true)}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000, // Ensures it stays above other elements
          }}
          className="helpout-button"
        >
          <HelpOutlineIcon
            style={{
              color: hoveredButton ? "#000000" : "#000000",
              transition: "0.2s ease-in-out",
              width: "30px",
              height: "30px",
            }}
          />
        </button> */}
      </div>
      <Tour
        scrollSmooth
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        styles={{ tourStyles }}
        goToStep={currentStep}
        getCurrentStep={(step) => setCurrentStep(step)}
      />
    </>
  );
};

export default AppTour;
