/* eslint-disable */

// This code is for v4 of the openai package: npmjs.com/package/openai
import OpenAI from "openai";
import { retrieveStoredEmbeddings, retrieveStoredData  } from "./helpers";


const openai = new OpenAI({
  apiKey: "sk-proj-GPCLdXllynODtIlUJDDiT3BlbkFJv5grBkM31saweFM0GDka",
  dangerouslyAllowBrowser: true,
});

/**
 * Cleans an HTML string by removing leading indentation, blank lines, and ellipsis.
 *
 * @param {string} htmlContent - The HTML content to process.
 * @returns {string} - The cleaned HTML content.
 */
const cleanHtmlContent = (htmlContent) => {
  if (typeof htmlContent !== 'string') {
    console.error('Input must be a string.');
    return '';
  }

  const removeLeadingWhitespace = htmlContent.replace(/^[ \t]+/gm, '');
  const removeBlankLines = removeLeadingWhitespace.replace(/^\s*$/gm, '');
  const cleanedElipsis = removeBlankLines.replace(
    /```html\s*([\s\S]*?)```/g,
    "$1"
  );

  return cleanedElipsis;
};

// export const sendMessageToGpt = async (conversationHistory, userInput) => {
//   // Construct the messages array based on conversation history and user input
//   const messages = conversationHistory.map((message) => ({
//     role: message.role,
//     content: message.content,
//   }));

//   // Add the user's latest message
//   if (userInput !== "") {
//     messages.push(userInput);
//   }

//   console.log(messages)
//   const response = await openai.chat.completions.create({
//     model: "gpt-4o",
//     messages: messages,
//     temperature: 0,
//     top_p: 1,
//     frequency_penalty: 0,
//     presence_penalty: 0,
//   });

//   // Extract the assistant's response from the API response
   
//   const assistantResponse = cleanHtmlContent(response.data[0].embedding);

//   return assistantResponse;
// };

// export const sendMessageToGpt = async (conversationHistory, userInput, dataObjects) => {
//   try {
//     // Validate inputs
//     if (!Array.isArray(conversationHistory)) {
//       throw new Error("Invalid conversation history provided.");
//     }

//     // if (!Array.isArray(dataObjects)) {
//     //   throw new Error("Invalid dataObjects provided.");
//     // }

//     // Construct the messages array based on conversation history
//     const messages = conversationHistory.map((message) => ({
//       role: message.role,
//       content: message.content,
//     }));

//     // Add the user's latest message
//     if (userInput && userInput.trim() !== "") {
//       messages.push({ role: "user", content: userInput });
//     }

//     console.log("Constructed messages:", messages);

//     // Include embedding data as context
//     const embeddingContext = {
//       role: "system",
//       content: `You have access to the following embedded data for analysis. Use it to answer the user's query accurately and contextually. Embedded data: ${JSON.stringify(dataObjects, null, 2)}.`,
//     };
//     messages.unshift(embeddingContext);

//     // (Optional) Token count validation to prevent exceeding limits
//     // const tokenCount = calculateTokens(messages);
//     // if (tokenCount > MAX_INPUT_TOKENS) {
//     //   throw new Error("Input exceeds maximum token limit. Please refine your query.");
//     // }

//     // Send the request to OpenAI's API
//     const response = await openai.chat.completions.create({
//       model: "gpt-4o",
//       messages: messages,
//       temperature: 0,
//       top_p: 1,
//       frequency_penalty: 0,
//       presence_penalty: 0,
//     });

//     // Extract and validate the assistant's response
//     if (!response.choices || response.choices.length === 0) {
//       throw new Error("No response received from OpenAI.");
//     }

//     const assistantResponse = response.choices[0].message.content;
//     return assistantResponse;
//   } catch (error) {
//     console.error("Error in sendMessageToGpt:", error.message || error);
//     return "An error occurred while processing your request. Please try again.";
//   }
// };

const MAX_INPUT_TOKENS = 100000; // Adjust this based on model's context length
const MAX_OUTPUT_TOKENS = 10000; // Ensure output stays manageable


// Helper function to truncate messages
// function truncateMessages(messages, maxTokens) {
//   let tokenCount = 0;
//   const truncated = [];
//   for (let i = messages.length - 1; i >= 0; i--) {
//     const messageTokens = messages[i].content.length / 4;
//     if (tokenCount + messageTokens > maxTokens) break;
//     truncated.unshift(messages[i]);
//     tokenCount += messageTokens;
//   }
//   return truncated;
// }

// export const sendMessageToGpt = async (conversationHistory, userInput, dataObjects) => {
//   try {
//     // Validate inputs
//     if (!Array.isArray(conversationHistory)) {
//       throw new Error("Invalid conversation history provided.");
//     }

//     // Construct the messages array based on conversation history
//     let messages = conversationHistory.map((message) => ({
//       role: message.role,
//       content: message.content,
//     }));

//     // Add the user's latest message
//     if (userInput && userInput.trim() !== "") {
//       messages.push({ role: "user", content: userInput });
//     }

//     // Include embedding data as context
//     const embeddingContext = {
//       role: "system",
//       content: `You have access to the following data objects for analysis. Use it to answer the user's query accurately and contextually. Data object: ${JSON.stringify(dataObjects, null, 2)}.`,
//     };

//     // Always ensure embedding context is at the beginning
//     messages.unshift(embeddingContext);

//     // console.log("Messages before tokenization and truncation:", JSON.stringify(messages, null, 2));

//     // Calculate tokens and truncate if necessary
//     const totalInputTokens = calculateTokens(messages);
//     if (totalInputTokens > MAX_INPUT_TOKENS) {
//       console.warn("Input exceeds limit. Truncating messages.");
//       messages = truncateMessages(messages, MAX_INPUT_TOKENS);
//       console.log(messages);

//     }


//     // Re-verify that the embedding context exists after truncation
//     // if (!messages.some((msg) => msg.role === "system")) {
//     //   console.warn("Embedding context missing after truncation. Re-adding it.");
//     //   // messages.unshift(embeddingContext);

//     //   // Re-truncate if adding the embedding context causes token overflow
//     //   const recheckedTokens = calculateTokens(messages);
//     //   if (recheckedTokens > MAX_INPUT_TOKENS) {
//     //     console.warn("Re-truncating after re-adding embedding context.");
//     //     messages = truncateMessages(messages, MAX_INPUT_TOKENS);
//     //   }
//     // }

//     // console.log("Final messages sent to OpenAI:", JSON.stringify(messages, null, 2));

//     // Ensure the messages array is not empty
//     if (messages.length === 0) {
//       throw new Error("The messages array is empty after processing.");
//     }

//     // Send the request to OpenAI's API
//     const response = await openai.chat.completions.create({
//       model: "gpt-4o",
//       messages: messages,
//       max_tokens: MAX_OUTPUT_TOKENS,
//       temperature: 0,
//       top_p: 1,
//       frequency_penalty: 0,
//       presence_penalty: 0,
//     });

//     // Extract and validate the assistant's response
//     if (!response.choices || response.choices.length === 0) {
//       throw new Error("No response received from OpenAI.");
//     }

//     const assistantResponse = response.choices[0].message.content;
//     return assistantResponse;
//   } catch (error) {
//     console.error("Error in sendMessageToGpt:", error.message || error);
//     return "An error occurred while processing your request. Please try again.";
//   }
// };

// Helper function to truncate large data objects
const truncateDataObjects = (dataObjects, maxLength = 100000) => {
  const jsonString = JSON.stringify(dataObjects, null, 2);
  return jsonString.length > maxLength
    ? jsonString.slice(0, maxLength) + "... (truncated)"
    : jsonString;
};

// Helper function to estimate tokens
const calculateTokens = (messages) =>
  messages.reduce((acc, message) => acc + message.content / 4, 0); // Approx. 4 chars/token
// function calculateTokens(messages) {
//   messages.forEach((message, index) => {
//     if (!message.content || typeof message.content !== "string") {
//       console.error(`Invalid message at index ${index}:`, message);
//     }
//   });
//   return messages.reduce((acc, message) => acc + (message.content.length / 4), 0);
// }

const splitIntoChunks = (dataObjects, maxTokens = 100000) => {
  const chunks = [];
  let currentChunk = [];
  let currentTokens = 0;

  for (const obj of dataObjects) {
    // Ensure the object has a `content` field to calculate tokens
    if (!obj.content || typeof obj.content !== "string") {
      console.error("Invalid data object:", obj);
      continue;
    }

    const objTokens = calculateTokens([obj]);

    // If adding this object exceeds the limit, save the current chunk and start a new one
    if (currentTokens + objTokens > maxTokens) {
      chunks.push([...currentChunk]); // Push a copy of the current chunk
      currentChunk = [];
      currentTokens = 0;
    }

    // Add the object to the current chunk
    currentChunk.push(obj);
    currentTokens += objTokens;

    // Warn if a single object exceeds the max token limit
    if (objTokens > maxTokens) {
      console.warn(
        `A single data object exceeds the max token limit (${objTokens} > ${maxTokens}). Consider splitting it further.`
      );
    }
  }

  // Add the last chunk if not empty
  if (currentChunk.length > 0) {
    chunks.push(currentChunk);
  }

  return chunks;
};

  

// Helper function to chunk messages
const chunkMessages = (messages, maxTokens) => {
  const chunks = [];
  let currentChunk = [];
  let currentTokens = 0;

  for (let message of messages) {
    const messageTokens = calculateTokens([message]);

    // If adding this message exceeds the limit, save the current chunk and start a new one
    if (currentTokens + messageTokens > maxTokens) {
      chunks.push(currentChunk);
      currentChunk = [];
      currentTokens = 0;
    }

    // Add the message to the current chunk
    currentChunk.push(message);
    currentTokens += messageTokens;

    // Warn if a single message exceeds the max token limit
    if (messageTokens > maxTokens) {
      console.warn(
        `A single message exceeds the max token limit (${messageTokens} > ${maxTokens}). Consider splitting it.`
      );
    }
  }

  // Add the last chunk if not empty
  if (currentChunk.length > 0) {
    chunks.push(currentChunk);
  }

  return chunks;
};

// Main Function
export const sendMessageToGpt = async (conversationHistory, userInput) => {
  // Construct the messages array based on conversation history and user input
  const messages = conversationHistory.map((message) => ({
    role: message.role,
    content: message.content,
  }));

  // Add the user's latest message
  if (userInput !== "") {
    messages.push(userInput);
  }

//   const prompt_template = `You will be provided with a json dataset originally from intuit quickbooks.\n
//                             Make sure to read and remember that data. Also, do not give me any explanations or formulas, simply provide the output the user is asking for based on the provided dataset. \n
//                             Return responses in html format. Here is this data ${jsonData}. Do not apologize, do not preface. Simply give me the information exactly how it's asked for. `;

  // every subsequent request needs to be in the form, user then assistant
  // messages has to be: {user, assistant, user, assistant}
  // console.log(messages)
  const response = await openai.chat.completions.create({
    model: "gpt-4o",
    messages: messages,
    temperature: 0,
    max_tokens: 2000,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
  });

  // Extract the assistant's response from the API response
  // console.log(response.choices);
  const assistantResponse = cleanHtmlContent(response.choices[0].message.content);

  return assistantResponse;
};


const constructPrompt = (userInput, topResults) => {
  const context = topResults
      .map((result, i) => `### Result ${i + 1}\nTitle: ${result.dataObject.title}\nContent: ${result.dataObject.content}`)
      .join("\n\n");

  return `You are a data analyst. Based on the following context answer the query: "${userInput}".\n\nContext:\n${context}
    Scan through the data thoroughly before returning a response. Please do not return beginAtZero equal true for the x scales. Always take a deep breadth. Do not rush. Do not generate a chart unless the user asks.
    The user may ask you to create a graph. In that case return a JSON with the chart.js v3 requirements to graph a graph.
    When the user asks for a text response, and you want to suggest that they can get a graphical representation as well, avoid technical suggestions
    like "I can generate the necessary JSON for a chart using chart.js." No need to mention JSON or chart.js. Speak as if the user is not an engineer.
    Include graph data in a format enclosed within <graph>...</graph> tags.
    Provide any graph data in a format enclosed within <graph> and </graph> tags. Use solid colors. maintainAspectRatio must always be set to false. For example:
    <graph>
    {
      "graphData": {
        "type": "line",
        "data": {
          "labels": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September"
          ],
          "datasets": [
            {
              "label": "Debt-to-Equity Ratio",
              "data": [
                52.75,
                43.33,
                3.52,
                11.68,
                4.11,
                64,
                32.12,
                51.35,
                90.85
              ],
              "borderColor": "rgba(75, 72, 192, 1)",
              "backgroundColor": "rgba(75, 72, 192, 1)",
              "borderWidth": 1,
              "fill": false
            }
          ]
        },
        "options": {
          "maintainAspectRatio": false,
          "responsive": true,
          "scales": {
            "y": {
              "axis": "y",
              "type": "linear",
              "beginAtZero": true,
              "ticks": {
                "minRotation": 0,
                "maxRotation": 50,
                "mirror": false,
                "textStrokeWidth": 0,
                "textStrokeColor": "",
                "padding": 3,
                "display": true,
                "autoSkip": true,
                "autoSkipPadding": 3,
                "labelOffset": 0,
                "align": "center",
                "crossAlign": "near",
                "showLabelBackdrop": false,
                "backdropColor": "rgba(255, 255, 255, 0.75)",
                "backdropPadding": 2,
                "color": "#666"
              },
              "display": true,
              "offset": false,
              "reverse": false,
              "bounds": "ticks",
              "clip": true,
              "grace": 0,
              "grid": {
                "display": true,
                "lineWidth": 1,
                "drawOnChartArea": true,
                "drawTicks": true,
                "tickLength": 8,
                "offset": false,
                "color": "rgba(0,0,0,0.1)"
              },
              "border": {
                "display": true,
                "dash": [],
                "dashOffset": 0,
                "width": 1,
                "color": "rgba(0,0,0,0.1)"
              },
              "title": {
                "display": false,
                "text": "",
                "padding": {
                  "top": 4,
                  "bottom": 4
                },
                "color": "#666"
              },
              "id": "y",
              "position": "left"
            },
            "x": {
              "axis": "x",
              "type": "category",
              "ticks": {
                "minRotation": 0,
                "maxRotation": 50,
                "mirror": false,
                "textStrokeWidth": 0,
                "textStrokeColor": "",
                "padding": 3,
                "display": true,
                "autoSkip": true,
                "autoSkipPadding": 3,
                "labelOffset": 0,
                "align": "center",
                "crossAlign": "near",
                "showLabelBackdrop": false,
                "backdropColor": "rgba(255, 255, 255, 0.75)",
                "backdropPadding": 2,
                "color": "#666"
              },
              "display": true,
              "offset": false,
              "reverse": false,
              "beginAtZero": false,
              "bounds": "ticks",
              "clip": true,
              "grid": {
                "display": true,
                "lineWidth": 1,
                "drawOnChartArea": true,
                "drawTicks": true,
                "tickLength": 8,
                "offset": false,
                "color": "rgba(0,0,0,0.1)"
              },
              "border": {
                "display": true,
                "dash": [],
                "dashOffset": 0,
                "width": 1,
                "color": "rgba(0,0,0,0.1)"
              },
              "title": {
                "display": false,
                "text": "",
                "padding": {
                  "top": 4,
                  "bottom": 4
                },
                "color": "#666"
              },
              "id": "x",
              "position": "bottom"
            }
          }
        }
      },
  }
    </graph>

  `;
};

function convertToHtml(text) {
  // Replace headers of varying levels based on number of # symbols
  const headerFormatted = text.replace(/^(#+) (.*?)$/gm, (_, hashes, content) => {
    const level = hashes.length; // Determine header level (h1, h2, h3, etc.)
    return `<h${level}>${content}</h${level}>`;
  });

  // Replace bold markers (**text**) with <strong> tags
  const boldFormatted = headerFormatted.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

  // Replace ordered list items (1. item) with <li> tags, wrapping in <ol>
  const orderedListFormatted = boldFormatted.replace(/^\d+\.\s(.*?)$/gm, "<li>$1</li>");
  const orderedListWrapped = orderedListFormatted.replace(/(<li>.*<\/li>)(?!<\/ol>)/gs, "<ol>$1</ol>");

  // Replace unordered list items (- item) with <li> tags, wrapping in <ul>
  const unorderedListFormatted = orderedListWrapped.replace(/^- (.*?)$/gm, "<li>$1</li>");
  const unorderedListWrapped = unorderedListFormatted.replace(/(<li>.*<\/li>)(?!<\/ul>)/gs, "<ul>$1</ul>");

  // Replace section titles (bold followed by a colon) with <h4> tags
  const sectionTitles = unorderedListWrapped.replace(/<strong>(.*?)<\/strong>:/g, "<h4>$1</h4>");

  return sectionTitles;
}

export const sendToGPT = async (userInput, results) => {
  
  const topResults = results.slice(0, 30); // Select top 30 results
  const prompt = constructPrompt(userInput, topResults);

  const response = await openai.chat.completions.create({
      model: "o1-mini",
      messages: [
          // { role: "system", content: "You are a highly knowledgeable data analyst." },
          { role: "user", content: prompt },
      ],
  });

  const assistantResponse = convertToHtml(response.choices[0].message.content);

  return assistantResponse;
};






