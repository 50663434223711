import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  cssVariables: true,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          fontFamily: `'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
                       "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
                       sans-serif`,
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          backgroundColor: '#F8FAFC',
          color: '#1E293B',
        },
        code: {
          fontFamily: `'Fira Code', source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace`,
        },
        th: {
          firstChild: {
            position: 'sticky',
            left: '-15px',
            backgroundColor: '#F8FAFC',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          padding: '8px 16px',
          fontWeight: 600,
          fontSize: '0.875rem',
          backgroundColor: '#3B82F6',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#2563EB',
          },
          '&.MuiButton-outlined': {
            backgroundColor: 'transparent',
            border: '1px solid #3B82F6',
            color: '#3B82F6',
            '&:hover': {
              backgroundColor: 'rgba(59, 130, 246, 0.04)',
            },
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            padding: '12px 16px',
            borderBottom: '1px solid #E2E8F0',
          },
          '& tbody': {
            backgroundColor: '#FFFFFF',
          },
          '& thead th': {
            color: '#1E293B',
            fontWeight: 600,
            textTransform: 'none',
            backgroundColor: '#F8FAFC',
          },
          '& tbody tr:hover': {
            backgroundColor: '#F8FAFC',
          },
          '& tbody tr th': {
            fontSize: '0.875rem',
            color: '#475569',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '0.875rem',
          '&.Mui-selected': {
            fontWeight: 600,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': {
              borderColor: '#E2E8F0',
            },
            '&:hover fieldset': {
              borderColor: '#CBD5E1',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#3B82F6',
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
          padding: '24px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '&.MuiAlert-success': {
            backgroundColor: '#ECFDF5',
            color: '#065F46',
          },
          '&.MuiAlert-error': {
            backgroundColor: '#FEF2F2',
            color: '#991B1B',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#3B82F6',
      light: '#60A5FA',
      dark: '#2563EB',
    },
    secondary: {
      main: '#10B981',
      light: '#34D399',
      dark: '#059669',
    },
    background: {
      default: '#F8FAFC',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#1E293B',
      secondary: '#475569',
    },
    error: {
      main: '#EF4444',
      light: '#F87171',
      dark: '#DC2626',
    },
    success: {
      main: '#10B981',
      light: '#34D399',
      dark: '#059669',
    },
  },
  typography: {
    fontFamily: `'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
                 "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
                 sans-serif`,
    h1: {
      fontSize: '2.25rem',
      fontWeight: 700,
      color: '#1E293B',
    },
    h2: {
      fontSize: '1.875rem',
      fontWeight: 700,
      color: '#1E293B',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 600,
      color: '#1E293B',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 600,
      color: '#1E293B',
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 600,
      color: '#1E293B',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
      color: '#1E293B',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      color: '#475569',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
      color: '#475569',
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
    '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
    // ... rest of the shadows array remains unchanged
  ],
});

export default theme;
