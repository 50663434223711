/* eslint-disable */

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import ConnectedIntegrations from '../../views/Settings/ConnectedIntegrations';
import { CardContentNoPadding } from './CustomStyles';
import StepperStyles from './Styles';
import { AuthContext } from '../../context/context';

export default function Finish(props) {
    const { state, dispatch } = React.useContext(AuthContext);
    function getLink(name) {
        if (name === "Quickbooks") {
            // dispatch({
            //     type: 'SET_PRESET_COMPONENT',
            //     payload: 'Finish'
            // });
            return "/projects";
        } else {
            return "/";
        }
    }

    return (
        (<div>
            <Card
                style={{
                    borderRadius: 10,
                    margin: "0 auto"
                }}

                sx={{ width: "1000px" }}>
                <CardContentNoPadding>
                    <Typography
                        style={StepperStyles.header}
                        gutterBottom
                        sx={{
                            color: "text.secondary",
                            fontSize: 30
                        }}>
                        3. Finish 🎉🚀✨
                    </Typography>

                    <div style={{
                        paddingLeft: "5%", paddingRight: "5%"
                    }}>
                        <Typography variant="body">
                            Finished Integrations
                        </Typography>
                        <br />
                        <ConnectedIntegrations />

                        <div style={{ maringLeft: "10%", maringRight: "10%" }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} >
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Link className="ui right floated primary button mb-4 mr-3" to={getLink(props.companyName)}
                                    style={{
                                        background: "black"
                                        , color: "white",
                                        textTransform: "none",
                                        padding: "8px 8px 8px 8px"

                                    }}
                                >
                                    Let's Get Started
                                </Link>
                            </Box>
                        </div>
                    </div>
                </CardContentNoPadding>
            </Card>
        </div>)
    );
}
