import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingBtn = (props) => (
  <>
    {props.prog ? (
      <>
        {props.functiondata !== null
          ? (
            <Button 
            variant="contained"
            className={props.styleprop}
            sx={{ width: "100%" }}
            // style={{ textTransform: 'none', fontSize: '15x', marginLeft: '10px' }} 
            onClick={() => props.onclick(props.functiondata)}
            >
              {' '}
              {props.name}
            </Button>
          )
          : (
            <Button 
              variant="contained"
              className={props.styleprop} 
              sx={{ width: "100%" }}
              // style={{ textTransform: 'none', fontSize: '15x', marginLeft: '10px' }} 
              onClick={() => props.onclick()}
            >
              {' '}
              {props.name}
            </Button>
          )}
      </>
    ) : (
      <>
        <div style={{ padding: '.5rem .5rem .5rem .5rem' }}>
          <CircularProgress
            color="inherit"
            size="30px"
            style={{
              color: '#58d99b', background: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center',
            }}
          />
        </div>
      </>
    )}
  </>

);

export default LoadingBtn;
