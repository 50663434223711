/* eslint-disable */

// import { Card } from '@mui/material';
import { Card, Button, ListItemIcon, ListItemText, Menu, MenuItem, IconButton, Box, Breadcrumbs } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { styled } from '@mui/material/styles';
import axios from "../../context/axios";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Typography from '@mui/material/Typography';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle, CircularProgress} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Navigate } from 'react-router-dom';
import { DatesRangeInput } from 'semantic-ui-calendar-react-17';
import { ButtonGroup, Icon } from "semantic-ui-react";
import { AuthContext } from '../../context/context';
import { getBalanceSheet, getCashFlow, getIncomeStatement, getIntegrationBoards, getInvitesBoardData, validateToken } from '../../context/intuit-service';
import Alerts from '../../views/Misc/Alerts';
import InviteBoard from '../../views/Settings/InviteBoard';
import DtGpt from '../Gpt/DtGpt';
import Lottie from 'lottie-react';
import confettiAnimation from './animation.json';
import IntegrationsButton from '../Projects/IntegrationsButton';
import IntegrationsButtonAddBoard from '../Projects/IntegrationsButtonAddBoard';
import { getUserPurchasing, updateGraphBoards } from '../../context/fetch-service';
import setUpAssistant from './AssistantHelper';

import { CardContentNoPadding } from '../Stepper/CustomStyles';
import { createDateRangeStrings, parseDate } from '../Workstation/Helpers/QuickbooksParser';
import Spinner from '../Workstation/Helpers/Spinner';
import BoardsView from './BoardsView';
import EditBoardButton from './EditBoardButton';
import LoadingCard from './LoadingCard';
import LoadingTab from './LoadingTab';
import NewBoard from './NewBoard';
import './LeftNavCSS.css';
import { countMatchingOwners, extractPermission, getDateRange, handleDateChangeFilterHelper } from './helpers';
import { parseInt } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import SportsScoreOutlinedIcon from '@mui/icons-material/SportsScoreOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import welcomeIllustration from '../../assets/undraw_welcome_nk8k.svg';
// import AppTour from '../AppTour';

const PREFIX = 'ClientPerformance';

const classes = {
  paper: `${PREFIX}-paper`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.paper}`]: { minWidth: '600px' }
}));

const ClientPerformance = () => {
  const {
    state: {
      integrations, integrationInfo, selectedIntegration, selectedTab,selectedBoard, allBoards, switchUser, purchasing, user, clientsPurchased, data_change, isFullScreen
    }, dispatch, toggleFullScreen,
  } = React.useContext(AuthContext);

  // const [exportClicked, setExportClicked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [date, setDate] = useState(getDateRange());
  const [msg, setMsg] = React.useState("")
  const [type, setType] = React.useState('success');
  const [openMsg, setOpenMsg] = React.useState(false);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBoards, setLoadingBoards] = useState(false);
  const [fullData, setFullData] = useState(null);
  const [fullDataTable, setFullDataTable] = useState(null);
  const [prevFullDataTable, setPrevFullDataTable] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const toggleFilter = () => setShowFilter((prev) => !prev);
  // const toggleDate = () => setShowDate(!showDate);
  const [isFocused, setIsFocused] = useState(false);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const location = useLocation();
  const [showConfetti, setShowConfetti] = useState(false);
  const [isSelected, setSelectedBoard] = useState(null);
  const [mySelectedBoard, setMySelectBoard] = useState(null);
  const [purchased, setPurchased] = useState(false);
  const [clientsBought, setClientsBought] = useState(0);
  const [hoveredBoard, setHoveredBoard] = useState(null);
  const [editClicked, setEditClicked] = useState(null);
  const [gptLoaded, setGptLoaded] = useState(false);
  const [showHighPriority, setShowHighPriority] = useState(true);
  const [showYourClients, setShowYourClients] = useState(true);
  const [showSharedClients, setShowSharedClients] = useState(true);
  const [panelOpen, setPanelOpen] = useState(true);

  const history = useNavigate();

  React.useEffect(() => {
     
     
     
  }, [editClicked, hoveredBoard, selectedBoard])

  // when you first purchase the clients, then add it to clientsPurchased and dispatch, after that don't keep adding.


  React.useEffect(() => {
    const fetchClients = async () => {
      try {
        const clients = await axios.post('/clients-bought', {email: user.email});
         
        setClientsBought(clients.data.clients);
        dispatch({
          type: 'SET_CLIENTS_PURCHASED',
          payload: clients.data.clients
        })
      }
      catch(error) {
        console.error(error);
      }
    };

    fetchClients();
  }, []);


  const handleCloseSuccessMessage = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setPurchaseSuccess(false);
  };

  const handleGetStartedClick = () => {
     
    handleCloseSuccessMessage();

    setShowConfetti(true);

    setTimeout(() => {
      setShowConfetti(false);
    }, 5000);

    history('/projects'); 
  }; 

  function categorizePlans(subscriptionData) {
    const categorizedData = {
      clients: [],
      editors: [],
    };

    const productMapping = {
      prod_OhUTJRysPqIDJA: 'clients',
      prod_OhURofTlRdkPM8: 'clients',
      prod_OhUSqKIYaZvFxJ: 'editors',
      prod_OhUQO75zUu0V35: 'editors',
    };

    subscriptionData.forEach((subscription) => {
      const { subscriptionDetails } = subscription;
      if (subscriptionDetails && subscriptionDetails.items && subscriptionDetails.items.data) {
        subscriptionDetails.items.data.forEach((item) => {
          if (item.plan && item.plan.product && productMapping[item.plan.product]) {
            const category = productMapping[item.plan.product];
            categorizedData[category].push({
              subscriptionID: subscriptionDetails.id,
              plan: item.plan,
              quantity: item.quantity,
            });
            console.log(categorizedData[category])
          }
        });
      }
    });

    return categorizedData;
  }
  // Simulate fetching purchasing details and updating the context
  const updatePurchasingDetails = async () => {
      try {
        let [purchasing] = await Promise.all([
          getUserPurchasing(),
        ]);

        let planDetails = categorizePlans(purchasing);
        purchasing = { ...purchasing, planDetails };
        // set context and add number of purchased clients to localStorage
        // temp fix, but will need to migrate to db
        console.log(purchasing)
        if (purchasing) {
          dispatch({
            type: "SET_PURCHASING",
            payload: purchasing,
          });
          const purchasedClients = purchasing.planDetails.clients[0].quantity;
           
          setClientsBought((prev) => prev+=purchasedClients);
          dispatch({
            type: 'SET_CLIENTS_PURCHASED',
            payload: clientsPurchased+purchasedClients
          })
          setPurchaseSuccess(true);
        }
      } catch (error) {
          console.error('Failed to fetch purchasing data:', error);
      }
  };

  useEffect(() => {
      // Check URL query parameters for Stripe success code
      const query = new URLSearchParams(location.search);
      const sessionSuccess = query.get('session_success');
      if (sessionSuccess === 'true') {
          updatePurchasingDetails();
      }
  }, [location.search]);

  useEffect(() => {
    console.log(clientsBought)
  }, [clientsBought])


  const [myFavorites, setMyFavorites] = useState(0);
  const [myBoards, setMyBoards] = useState(0);
  const [sharedBoards, setSharedBoards] = useState(0);

  React.useEffect(() => {
    const checkAndValidateToken = async () => {
      if (!integrationInfo) return; // Early return if integrationInfo is not available
  
      setLoading(true);
      
      let data = {
        realmId: integrationInfo.integrations[selectedIntegration].realmId,
        refresh_token: integrationInfo.integrations[selectedIntegration].refresh_token,
        access_token: "" // Default to empty string, updated below if applicable
      };
  
      if (integrations.quickbooks.access.token) {
        data.access_token = integrations.quickbooks.access.token.access_token;
      }
      
      try {
        const validatedToken = await validateToken(data);
        if (validatedToken.status === 201) {
          let token = validatedToken.data.token;
          let access = {
            code: "",
            realmId: integrationInfo.integrations[selectedIntegration].realmId,
            state: "",
            token: token,
          };
          dispatch({
            type: 'SET_QUICKBOOK_AUTH',
            payload: access,
          });
        }
        setMsg("");
        setType("");
        setOpenMsg(false);
      } catch (error) {
        console.error("Error during token validation:", error);
        setMsg("Please click the button to the right to refresh your QuickBooks access token");
        setType("error");
        setOpenMsg(true);
      } finally {
        setLoading(false);
      }
    };
  
    checkAndValidateToken();
  }, [selectedIntegration, integrationInfo, integrations.quickbooks.access.token]);

  const checkAccess = () => {
    // if owner or you can modify then you have access to editing boards
    const access = (allBoards.boards[selectedBoard].access && extractPermission(allBoards.boards[selectedBoard].access) === "CAN_MODIFY") || allBoards.boards[selectedBoard].owner === switchUser.userName;

    return access;
  };


  React.useEffect(() => {
    const fetchData = async () => {
      if (allBoards && allBoards.boards) {
        let myBoards = allBoards.boards.filter(board => board.owner === allBoards.userId && board.favorite === "false");
        let sharedBoards = allBoards.boards.filter(board => board.owner !== allBoards.userId);
        let favorites = allBoards.boards.filter(board => board.favorite === "true" && board.owner === allBoards.userId);
        setMyBoards(myBoards.length);
        setMySelectBoard(allBoards.boards[selectedBoard]);

        setSharedBoards(sharedBoards.length);
        setMyFavorites(favorites.length);
      }
      if (fullData === null && integrations && integrations.quickbooks && integrations.quickbooks.access
        && integrations.quickbooks.access.token && integrations.quickbooks.access.token.access_token) {
        try {
          await getLiveData(false);
        } catch (error) {
          console.error("Error fetching live data:", error);
        }
      }
    };
  
    fetchData();
  }, [allBoards, fullData, integrations]);


  React.useEffect(() => {
    const fetchBoards = async () => {
      getBoards(false);
    };
  
    fetchBoards();
  }, []);

  React.useEffect(() => {
    const fetchLiveData = async () => {
      if (integrations && integrations.quickbooks && integrations.quickbooks.access
          && integrations.quickbooks.access.token && integrations.quickbooks.access.token.access_token) {
        try {
          await getLiveData(false);
        } catch (error) {
          console.error("Error fetching live data:", error);
        }
      }
    };
  
    fetchLiveData();
  }, [integrations]);

  const handleDateChange = (event, { name, value }) => { setDate(value); };

  const liRef = useRef(null);

  useEffect(() => {
    const li = liRef.current;

    const showButton = () => setSelectedBoard(true);
    const hideButton = () => setSelectedBoard(false);

    if (li) {
      li.addEventListener('mouseenter', showButton);
      li.addEventListener('mouseleave', hideButton);
    }

    return () => {
      if (li) {
        li.removeEventListener('mouseenter', showButton);
        li.removeEventListener('mouseleave', hideButton);
      }
    };
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const disabled = (allBoards && allBoards.boards &&
    allBoards.boards[selectedBoard] &&
    allBoards.boards[selectedBoard].owner &&
    allBoards.userId)
    ? (allBoards.boards[selectedBoard].owner !== allBoards.userId &&
      (allBoards.boards[selectedBoard].access && extractPermission(allBoards.boards[selectedBoard].access) !== "CAN_MODIFY"))
    : false;
  const handleDateChangeFilter = (name) => {
    let dateValue = handleDateChangeFilterHelper(name)
    setDate(dateValue);
  };

  const handleBoardChange = async (index) => {
    dispatch({
      type: 'SET_SELECTEDBOARD',
      payload: index
    });
  };

  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExportToPDF = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageHeight = pdf.internal.pageSize.getHeight();
    const pageWidth = pdf.internal.pageSize.getWidth();

     
    const myBoardsLayout = mySelectedBoard.tabs[selectedTab].layout;
    const elementWidth = 10;
    let elementHeight = 0;

    // Sort myBoardsLayout first by y, then by x
    myBoardsLayout.sort((a, b) => {
        if (a.y === b.y) {
            return a.x - b.x;
        }
        return a.y - b.y;
    });

    myBoardsLayout.forEach(item => {
        elementHeight = Math.max(elementHeight, item.y + item.h);
    });
     

    const element = document.getElementById('pdfExportContent');
    const gridLayout = element.getElementsByClassName('react-grid-layout')[0];
    const gridItems = Array.from(gridLayout.children);

    let pdfY = 0;
    let current_i = 0;
    let lastY = null;
    let lastHeight=null;
    for (let index = 0; index < myBoardsLayout.length; index++) {
        const layoutItem = myBoardsLayout[index];
        const { w, h, x, y, i } = layoutItem;
        const gridItem = gridItems[i];
        if(index==0){
          const nextGridItem=gridItems[parseInt(i)+1];
          const nextItem=Array.from(nextGridItem.children)[0];
        }

        if (!gridItem) continue;

        const item = Array.from(gridItem.children)[0];

        // console.log(w, h, x, y, parseInt(i));
        const widthRatio = (w / elementWidth); // Proportional width
        const leftRatio = (x / elementWidth); // Proportional left position

        // Convert the item to a canvas
        await html2canvas(item, { scale: 3 }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');

            // Calculate proportional sizes for the PDF
            const pdfWidth = widthRatio * pageWidth;
            const pdfHeight = pdfWidth * (canvas.height / canvas.width);
            const pdfX = leftRatio * pageWidth;
            const actualPageHeight = (pageWidth * (element.clientHeight / element.clientWidth));
            const totalPages = Math.ceil(actualPageHeight / pageHeight);
            //  

            //  

            if (lastY !== null && y !== lastY) {
              pdfY += lastHeight;
            }

            if (pdfY + pdfHeight > pageHeight) {
                pdf.addPage();
                pdfY = 0;
                 
            }
            //  
            pdf.addImage(imgData, 'PNG', pdfX, pdfY, pdfWidth, pdfHeight);
            lastHeight=pdfHeight;

            lastY = y;

            if (current_i === myBoardsLayout.length - 1) {
              const saveName=allBoards.boards[selectedBoard].boardName+allBoards.boards[selectedBoard].tabs[selectedTab].title;
              pdf.save(`${saveName}.pdf`);
              handleMenuClose();
               
            }
            current_i++;
        });
      }

  };

  const toggleSection = (section) => {
    if (section === 'highPriority') setShowHighPriority(!showHighPriority);
    if (section === 'yourClients') setShowYourClients(!showYourClients);
    if (section === 'sharedClients') setShowSharedClients(!showSharedClients);
  };






  const getBoards = (selectedBoard) => {
    if (Object.keys(allBoards).length === 0) {
      setLoadingBoards(true);
    }
    let boardsId = integrationInfo.integrations[selectedIntegration].boardsId || "";

    Promise.all([
      getIntegrationBoards({ boardsId }),
      getInvitesBoardData()
    ])
      .then(([boardsData, invitesData]) => {
         
         
        let combinedBoardData = [...boardsData.data.boards.boards];

        if (Array.isArray(invitesData.data)) {
          combinedBoardData.push(...invitesData.data);
        }

        boardsData.data.boards.boards = combinedBoardData;
        dispatch({
          type: 'SET_ALLBOARDS',
          payload: boardsData.data.boards
        });

        if (selectedBoard) {
          for (let i = boardsData.data.boards.boards.length - 1; i >= 0; i--) {
            if (boardsData.data.boards.boards[i].access.length === 0) {
               
              dispatch({
                type: "SET_SELECTEDBOARD",
                payload: i,
              });
              break;
            }
          }
        }

        setLoadingBoards(false);
      })
      .catch((error) => {
        // Handle errors for both API calls
        // console.error("Error:", error);
        setLoading(false);
        setMsg("Error while fetching data");
        setType("error");
        setOpenMsg(true);
        setLoadingBoards(false);
      });

  };

  const refreshGraphAssistantBoards = async (data) => {
    const boards = allBoards.boards[selectedBoard].tabs[selectedTab].boardData
    let handler = []
    boards.forEach((board) => {
       
      if (board.boardData[0]?.query) {
        const foundBoard = board.boardData[0];
        handler.push({id: board._id, query: foundBoard.query});
      }
    });
    let res = []
    for (let obj of handler) {
      res.push(await setUpAssistant(data, obj.query, date, true, obj.id))
    }
    try {
      // an array of id of board and new config
      // const res = await Promise.all(promises);
      //  
       
      
      let boardsId = integrationInfo.integrations[selectedIntegration].boardsId || "";

      const boardSchema = {
        boardId: boardsId,
        clientId: allBoards.boards[selectedBoard]._id,
        tabId: allBoards.boards[selectedBoard].tabs[selectedTab]._id,
        data: res
      };

      const updatedBoards = await updateGraphBoards(boardSchema);

       

      dispatch({
        type: 'SET_ALLBOARDS',
        payload: updatedBoards.data.data
      })

      // this still has old data, meaning it's not saving for some reason in the backend
      const newData = await getIntegrationBoards({ boardsId });
       
      
    }
    catch (error) {
      console.error(error.message);
    }
  }


  const getLiveData = async (flag) => {
    // whenver we change the date of our data, reset AI Assistant
    dispatch({
      type: 'SET_DATE_CHANGE',
      payload: date
    })
    dispatch({
      type: 'SET_DATA_CHANGE',
      payload: data_change === 0 ? 1 : 0
    });
    // when changing the data, we don't want user to access the AI Assistant
    setGptLoaded(false);
    if (allBoards && allBoards.boards && allBoards.boards.length !== 0) {
      if (!flag) {
        setLoading(true);
      }
      let query = parseDate(date);
      let data = {
        realmId: integrations.quickbooks.access.realmId,
        access_token: integrations.quickbooks.access.token.access_token,
        refresh_token: integrations.quickbooks.access.token.refresh_token,
        singleQuery: query,
        query: [query],
        compare: true,
      };
      let [startDateString, endDateString] = date?.split(" - ");
      let ranges = createDateRangeStrings(startDateString, endDateString);
      let months = ranges.map((dateRangeStr) => {
        let [, startDateStr, endDateStr] = dateRangeStr.match(/start_date=(.*)&end_date=(.*)/) || [];
        let startDate = new Date(startDateStr);
        let endDate = new Date(endDateStr);
        return endDate.toLocaleDateString("en-US", { year: "numeric", month: "short" });
      });

      months.unshift("Title");
      setTableHeaders(months);
      data.query = ranges;

      try {
        const [incomeStatementResponse, balanceSheetResponse, cashFlowResponse] = await Promise.all([
          getIncomeStatement(data),
          getBalanceSheet(data),
          getCashFlow(data)
        ]);

        const IncomeStatement = incomeStatementResponse;
        const BalanceSheet = balanceSheetResponse;
        const CashFlow = cashFlowResponse;

        const refactorFinancialData = (data, transformItem = null) => {
          return data.flatMap(entry => {
            const entryRepresentation = {
              ...(transformItem ? transformItem(entry, null) : entry),
              description: entry.name,
              header: true,
            };
            const items = entry.items && entry.items.length > 0 ? entry.items : [];
            const transformedItems = items.map(item => {
              const transformedItem = transformItem ? transformItem(item, entry) : item;
              return {
                ...transformedItem,
                description: entry.name,
                header: true,
              };
            });
        
            return [entryRepresentation, ...transformedItems];
          });
        };
        const combinedArr = [];
        const refactoredIncomeStatement = refactorFinancialData(IncomeStatement.data.table);
        const refactoredBalanceSheet = refactorFinancialData(BalanceSheet.data.table);
        const refactoredCashFlow = refactorFinancialData(CashFlow.data.table);
        combinedArr.push(...refactoredIncomeStatement);
        combinedArr.push(...refactoredBalanceSheet);
        combinedArr.push(...refactoredCashFlow);

         

        const combinedArrPrev = [];
        combinedArrPrev.push(...IncomeStatement.data.table);
        combinedArrPrev.push(...BalanceSheet.data.table);
        combinedArrPrev.push(...CashFlow.data.table);
         
        const fetchedData = combinedArrPrev;
        setPrevFullDataTable(combinedArrPrev);

        setFullDataTable(combinedArr);
        setGptLoaded(true);
        // 

        setFullData({
          "Income Statement": IncomeStatement,
          "Balance Sheet": BalanceSheet,
          "Cash Flow": CashFlow,
        });
        if (!flag) {
          setLoading(false);
        }

        return fetchedData;
      } catch (error) {
        console.error("Error while fetching data:", error);
        setLoading(false);
      }
    }
  }

  const handleApplyClick = async () => {
    setLoading(true);
    const data = await getLiveData(true);
     
    await refreshGraphAssistantBoards(data);
    setLoading(false);
  };

  // const favoriteBoardToggle = async (boards) => {
  //   let favorite = "false"
  //   if (boards.boards[selectedBoard].favorite === "false") {
  //     favorite = "true"
  //   }
  //   let data = { sharedId: boards.boards[selectedBoard].owner ?? null, boardId: boards._id, favorite: favorite, clientId: allBoards.boards[selectedBoard]._id, boardIdReplace: boards.boards[selectedBoard]._id }
  //   const updateBoard = await favoriteBoard(data);
  //   boards.boards[selectedBoard].favorite = favorite;
  //   dispatch({
  //     type: 'SET_ALLBOARDS',
  //     payload: boards
  //   });
  // };
  const handle = useFullScreenHandle();

  // Function to handle full-screen toggle
  const handleFullScreenToggle = () => {
    if (isFullScreen) {
      handle.exit();
    } else {
      handle.enter();
    }
  };

  // Sync context state with actual fullscreen state
  const handleFullScreenChange = (state) => {
    if (isFullScreen !== state) {
      toggleFullScreen();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && isFullScreen) {
        handle.exit(); // Exit fullscreen
      }
    };

    window.addEventListener('keydown', handleKeyDown); // Add event listener for keydown

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFullScreen, handle]);

  return (
    (<Root>
      <>
        {Object.keys(integrationInfo).length === 0 ||
        integrationInfo.integrations.length < 1 ? (
          <Navigate replace to="/company" />
        ) : (
          <div className="">
            <div
              className="row"
              style={{ display: "grid", gridTemplateColumns: "1fr" }}
            >
              <div
                className="leftNav pt-3"
                style={{ borderRight: "1px solid rgb(222, 222, 223)" }}
              >
                {loadingBoards ? (
                  <Spinner height={"500px"} />
                ) : (
                  <Card
                    style={{
                      borderRadius: 15,
                      margin: "0 auto",
                      padding: "10px 10px 10px 10px",
                      borderBottom: "0 none !important",
                      boxShadow: "none",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    <CardContentNoPadding>
                      {allBoards && allBoards.boards && (
                        <div className="row pl-3 mb-3">
                          <ul class="board-list">
                            <li>
                              <NewBoard
                                getBoards={getBoards}
                                boardId={allBoards._id}
                              />
                            </li>
                          </ul>
                        </div>
                      )}

                      {/* High Priority Section */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => toggleSection("highPriority")}
                      >
                        <Typography
                          gutterBottom
                          sx={{
                            color: "text.primary",
                            fontSize: 15,
                            fontWeight: 700,
                            paddingBottom: 1,
                            paddingTop: 1,
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer"
                          }}>
                          <StarBorderOutlinedIcon sx={{ marginRight: "10px" }} />
                          High Priority
                        </Typography>
                        <IconButton size="large">
                          <ExpandMoreIcon
                            style={{
                              transform: showHighPriority
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                              transition: "transform 0.3s",
                            }}
                          />
                        </IconButton>
                      </div>
                      {showHighPriority && (
                        <div className="pl-1 pt-1 mb-3">
                          {allBoards &&
                            allBoards.boards &&
                            allBoards.boards.map((board, i, selectedIndex) => {
                              const isSelected = i === selectedBoard;
                              const handleBoardClick = async () => {
                                handleBoardChange(i); // Assuming `i` is the property you want to pass
                                setSelectedBoard(i);
                              };

                              const handleBoardHover = (event, hover) => {
                                 
                                if (hover) {
                                  setHoveredBoard(i);
                                  setEditClicked(null);
                                } else {
                                  setHoveredBoard(null);
                                }
                              };

                              const handleEditClicked = (edit) => {
                                if (edit) {
                                  setEditClicked(i);
                                } else {
                                  setEditClicked(null);
                                }
                              };

                              return (
                                <>
                                  {board &&
                                    board.favorite === "true" &&
                                    board.owner === allBoards.userId && (
                                      <>
                                        <div
                                          className="row pl-3 box-border"
                                          key={i}
                                        >
                                          <ul class="board-list">
                                            <li
                                              style={{
                                                cursor: `${
                                                  hoveredBoard === i
                                                    ? "pointer"
                                                    : "default"
                                                }`,
                                              }}
                                              ref={liRef}
                                              class={`board-item ${
                                                isSelected
                                                  ? "selected-board"
                                                  : ""
                                              } ${
                                                i === isFocused
                                                  ? "focused-board"
                                                  : ""
                                              }`}
                                              onMouseEnter={(e) =>
                                                handleBoardHover(e, true)
                                              }
                                              onMouseLeave={(e) =>
                                                handleBoardHover(e, false)
                                              }
                                            >
                                              <div
                                                class="col-sm-9"
                                                onFocus={() => setIsFocused(i)}
                                                onBlur={() =>
                                                  setIsFocused(false)
                                                }
                                                onClick={handleBoardClick}
                                              >
                                                <button
                                                  class="board-button"
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                    minWidth: "max-content",
                                                    fontSize: "3rem",
                                                    paddingLeft: "0px",
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                  }}
                                                >
                                                  {/* <PriorityHighIcon
                                                    sx={{ color: "#FFD700" }}
                                                  /> */}
                                                  <span
                                                    class="board-name"
                                                    style={{
                                                      textAlign: "left",
                                                      color: isSelected ? "rgb(0 135 255)" 
                                                        : hoveredBoard === i
                                                        ? "rgb(0 135 255)" // Color change on hover
                                                        : "black",
                                                      transition: "color 0.2s ease-in-out",   
                                                    }}
                                                  >
                                                    {board.boardName}
                                                  </span>
                                                </button>
                                              </div>
                                              {hoveredBoard === i && (
                                                <div class="col-sm-2">
                                                  <EditBoardButton
                                                    onClick={() =>
                                                      console.log(
                                                        "Edit clicked"
                                                      )
                                                    }
                                                    board={board}
                                                    boardId={allBoards._id}
                                                    getBoards={getBoards}
                                                    setEditClicked={
                                                      handleEditClicked
                                                    }
                                                    onMouseEnter={(e) =>
                                                      handleBoardHover(e, true)
                                                    }
                                                    onMouseLeave={(e) =>
                                                      handleBoardHover(e, false)
                                                    }
                                                  />
                                                </div>
                                              )}
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    )}
                                </>
                              );
                            })}
                          {myFavorites === 0 && (
                            <Typography
                              gutterBottom
                              sx={{
                                color: "text.secondary",
                                fontSize: 15
                              }}>
                              No projects prioritized
                            </Typography>
                          )}
                        </div>
                      )}
                      {/* Your Clients Section */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => toggleSection("yourClients")}
                      >
                        <Typography
                          gutterBottom
                          sx={{
                            color: "text.primary",
                            fontSize: 15,
                            fontWeight: 700,
                            paddingBottom: 1,
                            paddingTop: 1,
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer"
                          }}>
                          <PeopleAltOutlinedIcon sx={{ marginRight: "10px" }} />
                          Your Projects
                        </Typography>
                        <IconButton size="large">
                          <ExpandMoreIcon
                            style={{
                              transform: showYourClients
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                              transition: "transform 0.3s",
                            }}
                          />
                        </IconButton>
                      </div>
                      {showYourClients && (
                        <div className="pl-1 pt-1 mb-3 box-border">
                          {allBoards &&
                            allBoards.boards &&
                            allBoards.boards.map((board, i) => {
                              const isSelected = i === selectedBoard;
                              const handleBoardClick = async () => {
                                 
                                {
                                  /* if (hoveredBoard === i && editClicked === i) {
                                   
                                  return;
                                } */
                                }
                                handleBoardChange(i); // Assuming `i` is the property you want to pass
                                setSelectedBoard(i);
                              };

                              const handleBoardHover = (hover) => {
                                if (hover) {
                                  setHoveredBoard(i);
                                } else {
                                  setHoveredBoard(null);
                                }
                              };

                              const handleEditClicked = (edit) => {
                                if (edit) {
                                  setEditClicked(i);
                                } else {
                                  setEditClicked(null);
                                }
                              };

                              return (
                                <>
                                  {board &&
                                    board.favorite === "false" &&
                                    board.owner === allBoards.userId && (
                                      <>
                                        <div
                                          className="row pl-3 box-border"
                                          key={i}
                                        >
                                          <ul class="board-list">
                                            <li
                                              style={{
                                                cursor: `${
                                                  hoveredBoard === i
                                                    ? "pointer"
                                                    : "default"
                                                }`,
                                              }}
                                              ref={liRef}
                                              class={`board-item ${
                                                isSelected
                                                  ? "selected-board"
                                                  : ""
                                              } ${
                                                i === isFocused
                                                  ? "focused-board"
                                                  : ""
                                              }`}
                                              onMouseEnter={() =>
                                                handleBoardHover(true)
                                              }
                                              onMouseLeave={() =>
                                                handleBoardHover(false)
                                              }
                                            >
                                              <div
                                                class="col-sm-9"
                                                onFocus={() => setIsFocused(i)}
                                                onBlur={() =>
                                                  setIsFocused(false)
                                                }
                                                onClick={handleBoardClick}
                                              >
                                                <button
                                                  class="board-button"
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                    textTransform: "none",
                                                    minWidth: "max-content",
                                                    fontSize: "1rem",
                                                    color: "black",
                                                    paddingLeft: "0px",
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                  }}
                                                >
                                                  {/* {board &&
                                                    !board.favorite &&
                                                    board.favorite === "true" && (
                                                      <PriorityHighIcon
                                                        sx={{ color: "#FFD700" }}
                                                      />
                                                    )} */}
                                                  <span
                                                    class="board-name"
                                                    style={{
                                                      textAlign: "left",
                                                      color: isSelected ? "rgb(0 135 255)" 
                                                      : hoveredBoard === i
                                                      ? "rgb(0 135 255)" // Color change on hover
                                                      : "black",
                                                      transition: "color 0.2s ease-in-out",
                                                    }}
                                                  >
                                                    {board.boardName}
                                                  </span>
                                                </button>
                                              </div>
                                              {hoveredBoard === i && (
                                                <div className="col-sm-2">
                                                  <EditBoardButton
                                                    board={board}
                                                    boardId={allBoards._id}
                                                    getBoards={getBoards}
                                                    setEditClicked={
                                                      handleEditClicked
                                                    }
                                                  />
                                                </div>
                                              )}
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    )}
                                </>
                              );
                            })}
                          {myBoards === 0 && (
                            <Typography
                              gutterBottom
                              sx={{
                                color: "text.secondary",
                                fontSize: 15
                              }}>
                              No projects to display
                            </Typography>
                          )}
                        </div>
                      )}

                      {/* Shared Clients Section */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => toggleSection("sharedClients")}
                      >
                        <Typography
                          gutterBottom
                          sx={{
                            color: "text.primary",
                            fontSize: 15,
                            fontWeight: 700,
                            paddingBottom: 1,
                            paddingTop: 1,
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer"
                          }}>
                          <FolderSharedOutlinedIcon
                            sx={{ marginRight: "10px" }}
                          />
                          Shared Projects
                        </Typography>
                        <IconButton size="large">
                          <ExpandMoreIcon
                            style={{
                              transform: showSharedClients
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                              transition: "transform 0.3s",
                            }}
                          />
                        </IconButton>
                      </div>
                      {showSharedClients && (
                        <div className="pl-1 pt-1 mb-3">
                          {allBoards &&
                            allBoards.boards &&
                            allBoards.boards.map((board, i) => {
                              const isSelected = i === selectedBoard;
                              const handleBoardClick = async () => {
                                 
                                {
                                  /* if (hoveredBoard === i && editClicked === i) {
                                   
                                  return;
                                } */
                                }
                                handleBoardChange(i); // Assuming `i` is the property you want to pass
                                setSelectedBoard(i);
                              };

                              const handleBoardHover = (event, hover) => {
                                if (hover) {
                                  setHoveredBoard(i);
                                  setEditClicked(null);
                                } else {
                                  setHoveredBoard(null);
                                }
                              };

                              return (
                                <>
                                  {board &&
                                    board.owner !== allBoards.userId && (
                                      <>
                                        <div className="row pl-3 box-border">
                                          <ul class="board-list">
                                            <li
                                              class={`board-item ${
                                                isSelected
                                                  ? "selected-board"
                                                  : ""
                                              } ${
                                                i === isFocused
                                                  ? "focused-board"
                                                  : ""
                                              }`}
                                              onFocus={() => setIsFocused(i)}
                                              onBlur={() => setIsFocused(false)}
                                              onClick={handleBoardClick}
                                              onMouseEnter={(e) =>
                                                handleBoardHover(e, true)
                                              }
                                              onMouseLeave={(e) =>
                                                handleBoardHover(e, false)
                                              }
                                            >
                                              <div class="col-sm-9">
                                                <button
                                                  class="board-button"
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                    textTransform: "none",
                                                    minWidth: "max-content",
                                                    fontSize: "1rem",
                                                    color: "black",
                                                    paddingLeft: "0px",
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                  }}
                                                >
                                                  {/* {board &&
                                                  !board.favorite &&
                                                  board.favorite === "true" && (
                                                    <PriorityHighIcon
                                                      sx={{ color: "#FFD700" }}
                                                    />
                                                  )} */}
                                                  <span
                                                    class="board-name"
                                                    style={{
                                                      textAlign: "left",
                                                      color: isSelected ? "rgb(0 135 255)" 
                                                      : hoveredBoard === i
                                                      ? "rgb(0 135 255)" // Color change on hover
                                                      : "black",
                                                      transition: "color 0.2s ease-in-out",
                                                    }}
                                                  >
                                                    {board.boardName}
                                                  </span>
                                                </button>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    )}
                                </>
                              );
                            })}
                          {sharedBoards === 0 && (
                            <Typography
                              gutterBottom
                              sx={{
                                color: "text.secondary",
                                fontSize: 15
                              }}>
                              No shared projects
                            </Typography>
                          )}
                        </div>
                      )}
                      <div>
                        <Typography
                          gutterBottom
                          sx={{
                            color: "text.secondary",
                            fontSize: 15
                          }}>
                          {
                            clientsBought -
                            countMatchingOwners(allBoards) +
                            " Projects Available"}
                        </Typography>
                      </div>
                    </CardContentNoPadding>
                  </Card>
                )}
              </div>

              {allBoards &&
                allBoards.boards &&
                allBoards.boards.length === 0 ? (
                  <></>
                  // <Box
                  //   sx={{
                  //     display: "flex",
                  //     flexDirection: "column",
                  //     position: "fixed",
                  //     left: "235px", // Position the panel to the right
                  //     top: "51px",
                  //     bottom: "0px",
                  //     width: "100%", // Adjust the width as needed
                  //     maxWidth: "400px",
                  //     height: "auto",
                  //     overflow: "hidden",
                  //     overflowY: "auto",
                  //     backgroundColor: "#ffffff", // Add your desired background color
                  //   }}
                  // >
                  //   <Box
                  //     sx={{
                  //       display: "flex",
                  //       flexDirection: "column",
                  //       alignItems: "center",
                  //       justifyContent: "flex-start",
                  //       height: "100%",
                  //       padding: "130px 20px 0px 20px", // Adjust padding as needed
                  //       textAlign: "center",
                  //     }}
                  //   >
                  //   <img src={welcomeIllustration} style={{ width: '70%' }} />
                  //   <Typography sx={{ width: "70%" }}>
                  //     <h2>Let's get started!</h2>
                  //   </Typography>
                  //   <Typography>
                  //     <p>
                  //       To get started, create a new client on the right.
                  //     </p>
                  //   </Typography>
                  //   </Box>
                  // </Box>
                ) : (
                  <Card
                  style={{
                    // borderRadius: 15,
                    margin: "0 auto",
                    padding: "0px 10px 0px 10px",
                    borderBottom: "0 none !important",
                    boxShadow: "none",
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  <DtGpt 
                    panelOpen={panelOpen} 
                    setPanelOpen={setPanelOpen} 
                    data={prevFullDataTable} 
                  />
  
                </Card>
  
              )}

              <Box className="gridLayout"
                sx={{ 
                  paddingLeft: panelOpen ? "645px" : "245px",
                  transition: "paddingLeft 0.5s ease-in-out", // Add transition for smooth open/close
 
                }}
              
              >
                <Alerts
                  msg={msg}
                  type={type}
                  openMsg={openMsg}
                  setOpenMsg={setOpenMsg}
                  button={true}
                />
                {allBoards &&
                  allBoards.boards &&
                  allBoards.boards.length !== 0 && (                   

                <div class="ui menu pl-3 pr-3" style={{ marginBottom: 0 }}>
                  <Box 
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start-between",
                      alignItems: "center",
                      padding: "10px 20px",
                      color: "black",
                    }}
                  > 
                      <Typography variant='h5' sx={{fontWeight: 600, margin: "auto" }}>
                      {/* {integrationInfo.integrations[selectedIntegration].companyName} */}
                      {allBoards &&
                        allBoards.boards &&
                        allBoards.boards.length !== 0 &&
                        selectedBoard < allBoards.boards.length && (
                          <>
                            {allBoards.boards[selectedBoard].boardName}
                          </>
                        )}
                    </Typography>
                  </Box>

                  <div
                    className="right menu"
                    style={{ marginBottom: 10, marginTop: 10, height: 40, display: "flex", gap: "10px" }}
                  >
                    {/* This snippet renders the button for Warren
                    {!disabled && gptLoaded && (
                      <DtGpt data={prevFullDataTable} />
                    )} */}
                    {allBoards &&
                      allBoards.boards &&
                      allBoards.boards.length !== 0 && (
                      <Button
                        tabIndex={-1}
                        onClick={toggleFilter}
                      >
                        <FilterAltIcon />
                        <span style={{ marginLeft: "5px" }}>
                        Filter
                        </span>
                      </Button>
                    )}
                    {allBoards &&
                      allBoards.boards &&
                      allBoards.boards.length !== 0 &&
                      selectedBoard < allBoards.boards.length &&
                      allBoards.boards[selectedBoard].owner ===
                        allBoards.userId && (
                          <InviteBoard
                            disabled={disabled}
                            boardUserId={allBoards._id}
                            boardId={allBoards.boards[selectedBoard]._id}
                            page={"Client_Performance"}
                          />
                      )}

                      {/* Toggle Full-Screen Button */}
                      {allBoards &&
                        allBoards.boards &&
                        allBoards.boards.length !== 0 && ( 
                          <Button
                            onClick={handleFullScreenToggle}
                          >
                            {isFullScreen && isFullScreen === "true" ? (
                              <>
                                <div
                                  style={{ display: "flex", alignItems: "center" }}
                                >
                                  <FullscreenExitIcon />
                                  <span style={{ marginLeft: "5px" }}>
                                    Exit Fullscreen
                                  </span>{" "}
                                  {/* Adjust spacing if needed */}
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{ display: "flex", alignItems: "center" }}
                                >
                                  <FullscreenIcon />{" "}
                                  <span style={{ marginLeft: "5px" }}>Present</span>{" "}
                                  {/* Adjust spacing if needed */}{" "}
                                </div>
                              </>
                            )}
                          </Button>
                        )}
                      {allBoards &&
                        allBoards.boards &&
                        allBoards.boards.length !== 0 && ( 
                          <>
                        <Button
                          // className="ui floating dropdown icon button"
                          onClick={handleMenuToggle}
                        >
                          <MoreHorizOutlinedIcon />
                          {/* <i className="dropdown icon pt-1"></i> */}
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={handleExportToPDF}>
                            <ListItemIcon>
                              <i className="download icon"></i>
                            </ListItemIcon>
                            <ListItemText primary="Export to PDF" />
                          </MenuItem>
                        </Menu>
                        </>
                      )}
                  </div>
                </div>
                )}
                {/* <hr style={{ color: "#DEDEDF" }} /> */}
                <div
                  className="row"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {showFilter && (
                    <div className="pb-1" style={{ color: "black" }}>
                      <span
                        className="row"
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                          margin: "10px 6px 6px 2px",
                          alignItems: "center",
                        }}
                      >
                        <ButtonGroup
                          style={{ color: "black", paddingLeft: "5px" }}
                        >
                          <Button
                            name="today"
                            basic
                            inverted
                            style={{ border: "1px solid #ddd", color: "black", background: "#fff" }}
                            onClick={() => handleDateChangeFilter("today")}
                          >
                            Today
                          </Button>
                          <Button
                            name="7D"
                            basic
                            inverted
                            style={{ border: "1px solid #ddd", color: "black", background: "#fff" }}
                            onClick={() => handleDateChangeFilter("7D")}
                          >
                            7D
                          </Button>
                          <Button
                            name="1M"
                            basic
                            inverted
                            style={{ border: "1px solid #ddd", color: "black", background: "#fff" }}
                            onClick={() => handleDateChangeFilter("1M")}
                          >
                            1M
                          </Button>
                          <Button
                            name="1Q"
                            basic
                            inverted
                            style={{ border: "1px solid #ddd", color: "black", background: "#fff" }}
                            onClick={() => handleDateChangeFilter("1Q")}
                          >
                            1Q
                          </Button>
                          <Button
                            name="2Q"
                            basic
                            inverted
                            style={{ border: "1px solid #ddd", color: "black", background: "#fff" }}
                            onClick={() => handleDateChangeFilter("2Q")}
                          >
                            2Q
                          </Button>
                          <Button
                            name="4Q"
                            basic
                            inverted
                            style={{ border: "1px solid #ddd", color: "black", background: "#fff" }}
                            onClick={() => handleDateChangeFilter("4Q")}
                          >
                            4Q
                          </Button>
                          <Button
                            name="1Y"
                            basic
                            inverted
                            style={{ border: "1px solid #ddd", color: "black", background: "#fff" }}
                            onClick={() => handleDateChangeFilter("1Y")}
                          >
                            1Y
                          </Button>
                        </ButtonGroup>
                        <DatesRangeInput
                          name="datesRange"
                          placeholder="From - To"
                          iconPosition="left"
                          value={date}
                          dateFormat="YYYY-MM-DD"
                          onChange={handleDateChange}
                          style={{ width: "250px" }}
                        />
                        <button
                          className="ui primary button mr-3"
                          style={{
                            backgroundColor: "Black",
                            width: "100px",
                            height: "35px",
                          }}
                          onClick={() => handleApplyClick()}
                        >
                          Apply
                        </button>
                      </span>
                    </div>
                  )}
                </div>
                {/* {showDate && (
                  
                )} */}
                {allBoards && allBoards.boards && (
                  <>
                  {loading && allBoards.boards.length !== 0 ? (
                        <div className="loading-container">
                          <div className="row" style={{ display: "flex", justifyContent: "center", margin: "1rem 0" }}>
                            <CircularProgress size={50} style={{ margin: "0 1rem" }} />
                          </div>
                        </div>                    
                        ) : (
                      <>
                        <div>
                          <FullScreen handle={handle} onChange={handleFullScreenChange}>
                            <Box
                              sx={
                                isFullScreen
                                  ? {
                                      alignItems: "center", // Center horizontally
                                      height: "100vh", // Full viewport height
                                      overflowY: "auto", // Enable vertical scrolling
                                      backgroundColor: "#fff", // Background color for contrast
                                    }
                                  : {}
                              }
                            >
                              {fullData &&
                                fullDataTable &&
                                !loading &&
                                allBoards.boards.length > 0 && (
                                  <BoardsView
                                    boards={allBoards}
                                    fullData={fullData}
                                    fullDataTable={fullDataTable}
                                    tableHeaders={tableHeaders}
                                    getBoards={getBoards}
                                    boardId={allBoards._id}
                                    disabled={disabled}
                                    date={date}
                                    warrenData={prevFullDataTable}
                                    panelOpen={panelOpen}
                                  />
                                )}
                            </Box>
                          </FullScreen>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className="center-container">
                  {allBoards &&
                    allBoards.boards &&
                    allBoards.boards.length === 0 && (
                      <NewBoard
                        getBoards={getBoards}
                        boardId={allBoards._id}
                        noClients={true}
                      />
                    )}
                </div>
                {/* <Box
                  className="fifth-step"
                  sx={{
                    display: "flex",
                  }}
                >
                  <AppTour />
                </Box> */}
              </Box>
            </div>
          </div>
        )}
      </>
      {purchaseSuccess && (
        <div
          style={{
            position: "fixed",
            top: -100,
            left: 0,
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pointerEvents: "none",
            zIndex: 9999,
          }}
        >
          <Lottie
            animationData={confettiAnimation}
            loop={false}
            play
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      )}
      <Dialog
        classes={{ paper: classes.paper }}
        open={purchaseSuccess}
        onClose={handleCloseSuccessMessage}
      >
        <DialogTitle>Purchase Successful🎉🎉🎉</DialogTitle>
        <DialogContent styles={{ width: "1200px" }}>
          <p>Your subscription has been updated successfully.</p>
          <Button
            className="btn btn-primary btn-padd"
            style={{
              marginTop: "10px",
              width: "100%",
              height: "46px",
              background: "black",
              color: "white",
            }}
            onClick={handleGetStartedClick}
          >
            Get Started
          </Button>
        </DialogContent>
      </Dialog>
    </Root>)
  );
};

export default ClientPerformance;