/* eslint-disable */
import React, { useEffect, useState } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import DeleteIcon from "@mui/icons-material/Delete";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { IconButton, Typography } from "@mui/material";
import { AuthContext } from "../../context/context";
import axios from "../../context/axios";

const File = ({ id, file, handleClickedFile, selectedFiles }) => {
  const {
    state: { uploadedFiles },
    dispatch,
  } = React.useContext(AuthContext);

  const [fileHover, setFileHover] = useState(false);

  // useEffect(() => {
  //    
  //    
  //    
  // }, []);

  const deleteFile = async (fileId) => {
    const data = {
      fileName: file.fileName,
    };
    try {
      const res = await axios.post("/deleteFile", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        const newFiles = uploadedFiles.filter((f) => f.id !== fileId);
        dispatch({
          type: "SET_UPLOADED_FILES",
          payload: newFiles,
        });

        // Also remove from selectedFiles if it's selected
        handleClickedFile(fileId, true); // Pass a flag to indicate deletion
      }else if(res.status === 500){
        console.error("Unable to delete file: " + data.fileName);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isSelected = selectedFiles.includes(id);
  // if(isSelected){
  //    
  // }

  return (
    <ListItem
      id={id}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 20px",
        borderBottom: "1px solid #ddd",
        backgroundColor: isSelected ? "#e0f7fa" : "#fff",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f0f0f0",
        },
      }}
      onClick={() => handleClickedFile(id)}
      onMouseEnter={() => setFileHover(true)}
      onMouseLeave={() => setFileHover(false)}
    >
      {/* File Icon */}
      <ListItemAvatar sx={{ minWidth: "40px" }}>
        <ArticleOutlinedIcon sx={{ color: "#616161" }} />
      </ListItemAvatar>

      {/* File Name Column */}
      <ListItemText
        primary={
          <Typography variant="body1" sx={{ fontWeight: 500, minWidth: "200px" }}>
            {file?.fileName || "Untitled"}
          </Typography>
        }
      />

      {/* File Size Column */}
      {/* <Typography variant="body2" sx={{ minWidth: "100px", textAlign: "center" }}>
        {file?.fileSize ? `${(file.fileSize / 1024).toFixed(2)} KB` : "N/A"}
      </Typography> */}

      {/* Date Column */}
      {/* <Typography variant="body2" sx={{ minWidth: "150px", textAlign: "center" }}>
        {file?.createdAt ? new Date(file.createdAt).toLocaleDateString() : "N/A"}
      </Typography> */}

      {/* Delete Button */}
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering file selection
            deleteFile(id);
          }}
          size="large"
          sx={{ color: "red" }}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default File;
