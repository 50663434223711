/* eslint-disable */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import FreshBooks from '../../assets/freshbooks.png';
import QuickBooks from '../../assets/qb-logo.svg';
import Xero from '../../assets/xero.png';
import Spinner from '../../components/Workstation/Helpers/Spinner';
import { AuthContext } from '../../context/context';
import { getIntegrationInfo, deleteIntegrationInfo, validateToken } from '../../context/intuit-service';
import { useStyles } from './styles.js';

// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
import { Box, Typography, Button, Grid, Modal } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ConnectedIntegrations() {
  const { state, dispatch } = useContext(AuthContext);
  const [msg, setMsg] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [type, setType] = useState("success");
  const [openMsg, setOpenMsg] = useState(false);
  const [prog, setProg] = useState(true);
  const [connectedBooks, setConnectedBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isValidatingToken, setIsValidatingToken] = useState(false);
  const [deletedOpen, setDeletedOpen] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState({});
  const [openModalId, setOpenModalId] = useState(null);

  const getIcon = {
    QuickBooks,
    Xero,
    Freshbooks: FreshBooks,
  };

  // Open and close modal logic
  const openModal = (id) => {
    if (openModalId !== id) {
      setOpenModalId(id); // Only update if necessary
    }
  };

  const handleClose = () => setOpenModalId(null);
  const handleDeletedOpen = () => setDeletedOpen(true);
  const handleDeletedClose = () => setDeletedOpen(false);

  // Fetch integration info
  const fetchIntegrationInfo = useCallback(async () => {
    if (loading) {
      return;
    }
    console.log("Fetching integration info...");
    setLoading(true);
    try {
      const books = await getIntegrationInfo();
      if (books && books.data) {
        if (books.data.integrations !== state.integrationInfo) {
          dispatch({
            type: "SET_INTEGRATIONINFO",
            payload: books.data.integrations,
          });
        }
        setConnectedBooks(books.data.integrations?.integrations || []);
      } else {
        setConnectedBooks([]);
      }
    } catch (error) {
      console.error("Error fetching integration info:", error);
      setConnectedBooks([]);
    } finally {
      setLoading(false);
      console.log("Integration info fetched successfully.");
    }
  }, [dispatch, state.integrationInfo]);

  // Validate token for QuickBooks
  const validateIntegrationToken = useCallback(async () => {
    if (!connectedBooks.length || isValidatingToken) return;
  
    console.log("Validating integration tokens...");
    setIsValidatingToken(true);
  
    let allTokensInvalid = true; // Track if all integrations fail
  
    for (const book of connectedBooks) {
      const data = {
        realmId: book.realmId,
        refresh_token: book.refresh_token,
      };
  
      try {
        const validatedToken = await validateToken(data);
  
        setIsTokenValid((prev) => ({
          ...prev,
          [book.integrationId]: validatedToken.status === 201, // Track by integrationId
        }));
  
        if (validatedToken.status === 201) {
          allTokensInvalid = false; // At least one token is valid
  
          dispatch({
            type: "SET_QUICKBOOK_AUTH",
            payload: {
              code: "",
              realmId: book.realmId,
              state: "",
              token: validatedToken.data.token,
            },
          });
        }
      } catch (error) {
        console.error(`Error validating token for ${book.integrationType}:`, error);
  
        setIsTokenValid((prev) => ({
          ...prev,
          [book.integrationId]: false, // Mark the specific integration as invalid
        }));
      }
    }
  
    // Set alert only if **all** integrations failed
    setAlertMessage(allTokensInvalid ? "No connected integrations found." : "");
  
    setIsValidatingToken(false);
  }, [connectedBooks, dispatch, state.integrations]);
  
  //Fetch integration info on mount
  useEffect(() => {
    fetchIntegrationInfo();
  }, [fetchIntegrationInfo]);

  //Validate token whenever connectedBooks change
  useEffect(() => {
    validateIntegrationToken();
  }, [connectedBooks, validateIntegrationToken]);

  // Delete integration logic
  const deleteIntegration = useCallback(
    async (integration) => {
      setProg(false);
      try {
        const { protocol, hostname, port } = window.location;
        const URL = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
        const data = {
          integrationId: integration.integrationId,
          URL,
        };
        const response = await deleteIntegrationInfo(data);
        if (response && response.data?.result?.integrations) {
          setConnectedBooks(response.data.result.integrations);
          dispatch({
            type: "SET_INTEGRATIONINFO",
            payload: response.data.result,
          });
        }
        dispatch({
          type: "SET_SELECTEDINTEGRATION",
          payload: 0,
        });
        handleClose();
        handleDeletedOpen();
      } catch (error) {
        console.error("Error deleting integration:", error);
      } finally {
        setProg(true);
      }
    },
    [dispatch]
  );

  return (
    <>
      {loading ? (
        <Spinner height={"100px"} />
      ) : (
        <>
          {/* Show alert message only if **all** integrations have invalid tokens */}
          {alertMessage && Object.values(isTokenValid).every((valid) => !valid) ? (
            <Typography variant="body1" color="error" align="center">
              {alertMessage}
            </Typography>
          ) : (
            connectedBooks.some(book => isTokenValid[book.integrationId]) ? ( // Ensure at least one valid token exists
              connectedBooks.map((book) => (
                isTokenValid[book.integrationId] && ( // Only map valid tokens
                  <React.Fragment key={book.integrationId}>
                    <Modal
                      open={openModalId === book.integrationId}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Are you sure you would like to disconnect this integration?
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          <div
                            className="ui right red button"
                            onClick={() => deleteIntegration(book)}
                            style={{ cursor: 'pointer', marginRight: '10px' }}
                          >
                            Yes
                          </div>
                          <div
                            className="ui right button"
                            onClick={handleClose}
                            style={{ cursor: 'pointer' }}
                          >
                            No
                          </div>
                        </Typography>
                      </Box>
                    </Modal>
      
                    <div className="ui segments" style={{ marginBottom: '20px' }}>
                      <div className="ui segment" style={{ textAlign: 'left' }}>
                        <img
                          src={getIcon[book.integrationType]}
                          alt={`${book.integrationType} Logo`}
                          className="pr-5"
                          style={{ maxWidth: '200px' }}
                        />
                      </div>
                      <div className="ui segment">
                        <div className="row">
                          <div className="col-sm-3">
                            <p style={{ fontWeight: "bold" }}>Account Info</p>
                          </div>
                          <div className="col-sm-3">
                            <p>{book.email}</p>
                          </div>
                          <div className="col-sm-3">
                            <p>{new Date(book.date).toLocaleDateString()}</p>
                          </div>
                        </div>
                      </div>
                      <div className="ui segment" style={{ textAlign: 'left' }}>
                        <div
                          className="ui right red button"
                          onClick={() => openModal(book.integrationId)}
                          style={{ cursor: 'pointer' }}
                        >
                          Disconnect
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              ))
            ) : (
              <Typography variant="h6" align="center">
                No connected integrations found.
              </Typography>
            )
          )}
  
          {/* Success Modal after Deleting Integration */}
          <Modal
            open={deletedOpen}
            onClose={handleDeletedClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                You're successfully disconnected from QuickBooks.
              </Typography>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
// export default ConnectedIntegrations;
